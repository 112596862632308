import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useFetcher from "hooks/useFetcher"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditCostPoolTemplateModal = ({
  isOpen,
  toggleModal,
  templateToEdit,
  setTemplateToEdit,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()

  const save = async () => {
    let cptId = ""
    let template = null
    if (templateToEdit.costPoolTemplateID == -1) {
      cptId = await api.createCostPoolTemplate(
        currentUser.userID,
        templateToEdit
      )
    } else {
      cptId = templateToEdit.costPoolTemplateID
      await api.updateCostPoolTemplate(currentUser.userID, templateToEdit)
    }
    template = { ...templateToEdit, costPoolTemplateID: cptId }
    toast.success("Template Saved")
    toggleModal()
    reload && reload(template)
  }

  if (templateToEdit == null) return
  else
    return (
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Rate
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="note"
              type="text"
              onChange={e =>
                setTemplateToEdit({
                  ...templateToEdit,
                  costPoolTemplateName: e.target.value,
                })
              }
              value={templateToEdit.costPoolTemplateName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <TextareaAutosize
              minRows={3}
              className="form-control"
              placeholder="Description"
              onChange={e =>
                setTemplateToEdit({
                  ...templateToEdit,
                  description: e.target.value,
                })
              }
              value={templateToEdit.description}
            />
          </div>
          <div className="form-check mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="ff-inac"
              checked={templateToEdit.inactive}
              onClick={e => {
                setTemplateToEdit({
                  ...templateToEdit,
                  inactive: !costPoolToEdit.inactive,
                })
              }}
            />
            <Label className="form-check-label" for="ff-inac">
              Inactive
            </Label>
          </div>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {/* {rateToEdit.bcRateID != -1 && (
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginRight: "auto" }}
              onClick={deleteRate}
            >
              Delete
            </button>
          )} */}
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
          >
            Save
          </button>
        </div>
      </Modal>
    )
}

export default EditCostPoolTemplateModal
