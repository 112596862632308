import { swalWithConfirmButton } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Input, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

export const getSum = (arr, prop) => {
  var sum = arr
    .filter(x => !isNaN(parseFloat(x[prop])))
    .reduce(function (a, b) {
      return a + parseFloat(b[prop])
    }, 0)
  return sum.toFixed(2)
}

const EditPreferenceWeightsModal = ({
  scenarioId,
  isOpen,
  toggleModal,
  reloadData,
  scenario
}) => {
  const currentUser = userService.getLoggedInUser()
  let [objectivesCopy, setObjectivesCopy] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (isOpen && scenarioId != undefined && scenarioId != null) {
      loadObjectives()
    }
  }, [isOpen, scenarioId])

  const loadObjectives = async () => {
    try {
      setIsLoading(true)
      var obj = await api.getObjectives(scenarioId)
      setObjectivesCopy(
        obj.filter(x=>x.objTypeID <= 0).map(o => {
          o.expanded = false
          if (o.measures && o.measures.length > 0) {
            o.measures = o.measures.filter(x=>x.objTypeID <= 0).map(m => {
              m.measurePercentCalculated =
                (m.measurePercent / 100) * o.evPercent
              return m
            })
          }
          return o
        })
      )
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  

  const saveWeights = async () => {
    let objectiveWithEmptyWeight = objectivesCopy.find(x => x.evFinal == "")
    if (objectiveWithEmptyWeight) {
      swalWithConfirmButton.fire({
        title: `Objective ${objectiveWithEmptyWeight.objectiveName} cannot have empty weight`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      return
    }

    let objSum = getSum(objectivesCopy, "evFinal")
    if (parseInt(objSum) !== 100) {
      swalWithConfirmButton.fire({
        title: `Objectives Preference Weights have to sum up to 100`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      return
    }

    let measuresSumProblem = false
    for (let i = 0; i < objectivesCopy.length; i++) {
      if (objectivesCopy[i].measures && objectivesCopy[i].measures.length > 0) {
        let measuresSum = getSum(objectivesCopy[i].measures, "measureFinalPerc")
        if (isNaN(measuresSum) || parseInt(measuresSum) !== 100) {
          measuresSumProblem = true
          break
        }
      }
    }

    if (measuresSumProblem) {
      swalWithConfirmButton.fire({
        title: `Measures Preference Weights have to sum up to 100`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      return
    }

    let objectivesToSave = objectivesCopy.filter(x => x.isDirty)
    let measuresToSave = []
    objectivesCopy.forEach(o => {
      let dirtyMeasures = o.measures.filter(x => x.isDirty)
      if (dirtyMeasures.length > 0) {
        measuresToSave = [...measuresToSave, ...dirtyMeasures]
      }
    })

    setIsSaving(true)
    await Promise.all([
      ...objectivesToSave.map(o => {
        return (async () => {
          await api.updateObjective(currentUser.userID, o)
        })()
      }),
      ...measuresToSave.map(m => {
        return (async () => {
          await api.updateMeasure(currentUser.userID, m)
        })()
      }),
    ])
    toast.success("Weights saved successfuly")
    setIsSaving(false)
    reloadData()
    toggleModal()
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Weights
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && (
          <table
            className="table table-bordered"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th></th>
                <th className="col-3">{scenario && scenario.objectiveName}</th>
                <th className="col-3">AHP Weight</th>
                <th className="col-3">Preference Weight</th>
                <th className="col-3">Global Weight</th>
              </tr>
            </thead>
            <tbody>
              {objectivesCopy.map(o => {
                return (
                  <React.Fragment key={o.objectiveID}>
                    <tr>
                      <td>
                        {o.measures && o.measures.length > 0 && (
                          <i
                            className={
                              o.expanded
                                ? "fas fa-chevron-up"
                                : "fas fa-chevron-down"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let objs = [...objectivesCopy]
                              let obj = objs.find(
                                x => x.objectiveID == o.objectiveID
                              )
                              obj.expanded = !obj.expanded
                              setObjectivesCopy(objs)
                            }}
                          />
                        )}
                      </td>
                      <td>{o.objectiveName}</td>
                      <td>{o.evPercent.toFixed(2)}</td>
                      <td>
                        <Input
                          type="text"
                          className="form-control"
                          value={o.evFinal}
                          onChange={e => {
                            if (
                              e.target.value == "" ||
                              !isNaN(parseFloat(e.target.value))
                            ) {
                              let objs = [...objectivesCopy]
                              let obj = objs.find(
                                x => x.objectiveID == o.objectiveID
                              )
                              obj.evFinal = e.target.value
                              obj.isDirty = true
                              setObjectivesCopy(objs)
                            }
                          }}
                        />
                      </td>
                      <td>{o.evPercent.toFixed(2)}</td>
                    </tr>
                    {o.measures && o.measures.length > 0 && o.expanded && (
                      <tr>
                        <td colSpan={5}>
                          <table
                            className="table table-bordered"
                            style={{ backgroundColor: "white" }}
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th className="col-3">Measure</th>
                                <th className="col-3">AHP Weight</th>
                                <th className="col-3">Preference Weight</th>
                                <th className="col-3">Global Weight</th>
                              </tr>
                            </thead>
                            <tbody>
                              {o.measures.map(m => {
                                return (
                                  <tr key={m.measureID}>
                                    <td></td>
                                    <td>{m.measureName}</td>
                                    <td>{m.measurePercent.toFixed(2)}</td>
                                    <td>
                                      {/* {m.measureFinalPerc} */}
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={m.measureFinalPerc}
                                        onChange={e => {
                                          if (
                                            e.target.value == "" ||
                                            !isNaN(parseFloat(e.target.value))
                                          ) {
                                            let objs = [...objectivesCopy]
                                            let obj = objs.find(
                                              x =>
                                                x.objectiveID == o.objectiveID
                                            )
                                            let measure = obj.measures.find(
                                              x => x.measureID == m.measureID
                                            )
                                            measure.measureFinalPerc =
                                              e.target.value
                                            measure.isDirty = true
                                            setObjectivesCopy(objs)
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>{m.measurePercentCalculated.toFixed(2)}</td>
                                  </tr>
                                )
                              })}
                              <tr>
                                <td></td>
                                <td></td>
                                <td>
                                  <b>{getSum(o.measures, "measurePercent")}</b>
                                </td>
                                <td>
                                  <b>
                                    {getSum(o.measures, "measureFinalPerc")}
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {getSum(
                                      o.measures,
                                      "measurePercentCalculated"
                                    )}
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>{getSum(objectivesCopy, "evPercent")}</b>
                </td>
                <td>
                  <b>{getSum(objectivesCopy, "evFinal")}</b>
                </td>
                <td>
                  <b>{getSum(objectivesCopy, "evPercent")}</b>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            {isSaving && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ marginRight: "10px" }}
              />
            )}
            <button
              type="button"
              className="btn btn-outline-secondary"
              style={{ marginRight: "10px" }}
              onClick={toggleModal}
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={saveWeights}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </Row>
      </div>
    </Modal>
  )
}

export default EditPreferenceWeightsModal
