import { handleResponse } from "helpers/handle-response"

export const mainApiUrl = process.env.REACT_APP_MAIN_API_URL

export const extraHeaders = {
  "Cache-Control":
    "no-cache, no-store, must-revalidate, pre-check=0, post-check=0, max-age=0, s-maxage=0",
  Pragma: "no-cache",
  Expires: "0",
  "X-Content-Type-Options": "nosniff",
  "X-XSS-Protection": "1; mode=block",
  "X-Frame-Options": "sameorigin",
  "Strict-Transport-Security": "max-age=31536000",
  //"Content-Security-Policy": "default-src 'self' *.zendesk.com *.acsbapp.com  *.gstatic.com *.googleapis.com; style-src 'self' 'unsafe-inline' 'unsafe-eval' *.gstatic.com *.googleapis.com *.acsbapp.com *.gstatic.com; font-src 'self' *.gstatic.com *.acsbapp.com acsbapp.com *.googleapis.com data:; connect-src 'self' *.zopim.io *.zdassets.com *.zendesk.com *.acsbapp.com *.amazonaws.com *.googleapis.com;  child-src 'self' *.acsbapp.com acsbapp.com *.accessibe.com;  form-action 'self' *.okta.com;  object-src 'none'; block-all-mixed-content;  img-src 'self' qa-defprofilebucket.s3.us-east-2.amazonaws.com defprofilebucket.s3.us-east-2.amazonaws.com *.zopim.io *.zdassets.com *.zendesk.com *.anychart.com *.acsbapp.com  *.gstatic.com *.googleapis.com data:;  script-src 'self' 'unsafe-inline' 'unsafe-eval'  *.jquery.com *.zdassets.com *.googleapis.com *.google.com *.gstatic.com *.anychart.com *.jqueryui.com *.jquery.org *.tempuri.org *.zopim.io *.zdassets.com *.zendesk.com *.acsbapp.com acsbapp.com  *.gstatic.com *.googleapis.com"
}

function getToken() {
  let data = JSON.parse(localStorage.getItem("currentData"))
  if (data !== undefined && data !== null && data.access_token !== undefined) {
    return `Bearer ${data.access_token}`
  } else {
    return ""
  }
}

const handleError = error => {
  console.log(
    "----------------------------------ERROR----------------------------"
  )
  console.log(error)
  console.log(
    "----------------------------------ERROR----------------------------"
  )
}

const getRequestOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
      ...extraHeaders,
    },
  }
}

const deleteRequestOptions = data => {
  return {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
      ...extraHeaders,
    },
    body: data && JSON.stringify(data),
  }
}

const postRequestOptions = data => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
      ...extraHeaders,
    },
    body: JSON.stringify(data),
  }
}

const putRequestOptions = data => {
  return {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
      ...extraHeaders,
    },
    body: JSON.stringify(data),
  }
}

const getActionItems = async (scope, scopeItemId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetActionItems/${scope}/${scopeItemId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getMenuItems = async (userId, scenarioId, viewName) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetMenuItems/${userId}/${scenarioId}/${viewName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPotentialCostVarialbes = async (
  scenarioId,
  costPoolTypeId,
  costPoolId,
  signal
) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetPotentialCostVariables/${scenarioId}/${costPoolTypeId}/${costPoolId}`,
    {
      ...getRequestOptions(),
      signal: signal,
    }
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParetoGridData = async (
  optModelId,
  objModelId,
  finModelId,
  orderBy
) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetParetoGridData/${optModelId}/${objModelId}/${finModelId}/${orderBy}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParetoGridDataSummary = async (optModelId, objModelId, finModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetParetoGridData_Summary/${optModelId}/${objModelId}/${finModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParetoItems = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelParetoItems/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptResults = async (
  scenarioId,
  objectiveId,
  optModelId,
  objModelId,
  finModelId
) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptResults/${scenarioId}/${objectiveId}/${optModelId}/${objModelId}/${finModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroups = async (scenarioId, resourceClassId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroups/${scenarioId}/${resourceClassId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupsByUser = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupsByUser/${userId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupsByTShirtBundleId = async tShirtBundleId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupsByTShirtBundleId/${tShirtBundleId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroupTShirts = async (
  userId,
  resourceGroupId,
  tShirtBundleId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroupTShirts/${userId}/${resourceGroupId}/${tShirtBundleId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNextTShirtBundleId = async () => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetNextTShirtBundleID`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupCapacityData = async (scenarioId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupCapacityData_Time/${scenarioId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroup = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroup/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCompareItems = async (altId1, altId2) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetCompareItems/${altId1}/${altId2}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeBaselines = async altId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetList/${altId}/baselines`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createResourceGroup = async (userId, resourceGroup) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CreateResourceGroup/${userId}`,
    postRequestOptions(resourceGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateResourceGroupResult = async (userId, resourceGroup) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateResourceGroup/${userId}`,
    postRequestOptions(resourceGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const clearResourceEstimates = async (alternativeId, yearId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/ClearResourceEstimates/${alternativeId}/${yearId}/${userId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroup = async (userId, resourceGroupId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroup/${userId}/${resourceGroupId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupRates = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupRates/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createResourceGroupRate = async (userId, resourceGroupRate) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CreateResourceGroupRate/${userId}`,
    postRequestOptions(resourceGroupRate)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroupRate = async (userId, resourceGroupRateId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroupRate/${userId}/${resourceGroupRateId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupContracts = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupContracts/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createResourceGroupContract = async (userId, resourceGroupContract) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CreateResourceGroupContract/${userId}`,
    postRequestOptions(resourceGroupContract)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroupContract = async (userId, resourceGroupContractId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroupContract/${userId}/${resourceGroupContractId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupUsers = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupUsers/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createResourceGroupUser = async (userId, resourceGroupUser) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CreateResourceGroupUser/${userId}`,
    postRequestOptions(resourceGroupUser)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroupUser = async (userId, resourceGroupUserId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroupUser/${userId}/${resourceGroupUserId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupCostPools = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupCostPools/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupCostPool = async costPoolId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupCostPool/${costPoolId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createResourceGroupCostPool = async (userId, resourceGroupCostPool) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CreateResourceGroupCostPool/${userId}`,
    postRequestOptions(resourceGroupCostPool)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroupCostPool = async (userId, resourceGroupCostPoolId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroupCostPool/${userId}/${resourceGroupCostPoolId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupCapacities = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupCapacities/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateResourceGroupCapacity = async (userId, resourceGroupCapacity) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateResourceGroupCapacity/${userId}`,
    postRequestOptions(resourceGroupCapacity)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupResults = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetResourceGroupResults/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const runSingleOptimization = async (userId, optModel) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/RunSingleOptimization/${userId}`,
    postRequestOptions(optModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const runMultiOptimization = async (userId, optModel) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/RunMultiOptimization/${userId}`,
    postRequestOptions(optModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCostVariables = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetCostVariables/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCostVariableSummary = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetCostVariableSummary_OptModel/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createCostVariable = async (scenarioId, itemId) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/CreateCostVariable/${scenarioId}/${itemId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteCostVariables = async costVariableId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/DeleteCostVariable/${costVariableId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getDependencies = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelDependencies/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createDependency = async (userId, optModelDependency) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/CreateOptModelDependency/${userId}`,
    postRequestOptions(optModelDependency)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateDependency = async (userId, optModelDependency) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModelDependency/${userId}`,
    postRequestOptions(optModelDependency)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteDependency = async (userId, optModelDependencyId) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/DeleteOptModelDependency/${userId}/${optModelDependencyId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModelGroups = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelGroups/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createOptModelGroup = async (userId, optModelGroup) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/CreateOptModelGroup/${userId}`,
    postRequestOptions(optModelGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateOptModelGroup = async (userId, optModelGroup) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModelGroup/${userId}`,
    postRequestOptions(optModelGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteOptModelGroup = async (userId, optModelGroupId) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/DeleteOptModelGroup/${userId}/${optModelGroupId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModelGroupAlternativeIds = async optModelGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelGroupAlternativeIDs/${optModelGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateOptModelGroupAlternativeIds = async (
  optModelGroupId,
  alternativeId,
  selected
) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModelGroupAlternative/${optModelGroupId}/${alternativeId}/${selected}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeForecastDates = async (
  userId,
  alternativeId,
  mode,
  startYear,
  numYears
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAlternativeForecastDates/${userId}/${alternativeId}/${mode}/${startYear}/${numYears}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeRevision = async (userId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateRevision/${userId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const removeAlternativeRevision = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/RemoveRevision/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const acceptAlternativeRevision = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/AcceptRevision/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTelerikUserReport = async telerikUserReportId => {
  return await fetch(
    `${mainApiUrl}/api/Report/GetTelerikUserReport/${telerikUserReportId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTelerikUserReports = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Report/GetTelerikUserReports/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTelerikUserReportAssociatedUserIds = async telerikUserReportId => {
  return await fetch(
    `${mainApiUrl}/api/Report/GetTelerikUserReportAssociatedUserIds/${telerikUserReportId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const shareTelerikUserReport = async (telerikUserReportId, userIds) => {
  return await fetch(
    `${mainApiUrl}/api/Report/ShareTelerikUserReport/${telerikUserReportId}`,
    postRequestOptions(userIds)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const generateBusinessCaseReportBackup = async bcId => {
  return await fetch(
    `${mainApiUrl}/api/Report/BackupBCReport/${bcId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModelObjectives = async (optModelId, scenarioId, objModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelObjectives/${optModelId}/${scenarioId}/${objModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateOptModelObjective = async (userId, objective) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModelObjective/${userId}`,
    postRequestOptions(objective)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModelAlternatives = async (
  scenarioId,
  optModelId,
  objModelId,
  finModelId
) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelAlternatives/${scenarioId}/${optModelId}/${objModelId}/${finModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateOptModelAlternative = async (userId, optModelAlternative) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModelAlternative/${userId}`,
    postRequestOptions(optModelAlternative)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModel = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModel/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateOptModel = async (userId, optModel) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModel/${userId}`,
    postRequestOptions(optModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUsersByUserId = async (userId, showInactive) => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUsersByUser/${userId}/${showInactive}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}


const verifyCode = async (code) => {
  return await fetch(
    `${mainApiUrl}/api/User/VerifyCode/${code}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUsersByUserNotifications = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUsersByUser_Notifications/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUser = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUser/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createWorkflowTasksScenario = async (scenarioId, workflowId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateWorkflowTasks_Scenario/${scenarioId}/${workflowId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskTypesForEnterprise = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskTypesForEnterprise/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getWorkflowsForEnterprise = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetWorkflowsForEnterprise/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUsersByEnterprise = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUsersByEnterprise/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const sendSms = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Email/SMSTest/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserScenarioListString = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserScenarioListString/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserByEmail = async email => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserByEmail?email=${email}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserInfoByEmail = async email => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserInfoByEmail?email=${email}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParticipantByEmail = async (scenarioId, email) => {
  return await fetch(
    `${mainApiUrl}/api/User/GetParticipantByEmail/${scenarioId}?email=${email}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParticipant = async participantId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetParticipant/${participantId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createCostPool = async (userId, costPool) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateCostPool/${userId}`,
    postRequestOptions(costPool)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createCostPoolTemplate = async (userId, costPoolTemplate) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateCostPoolTemplate/${userId}`,
    postRequestOptions(costPoolTemplate)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateCostPoolTemplate = async (userId, costPoolTemplate) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateCostPoolTemplate/${userId}`,
    postRequestOptions(costPoolTemplate)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteCostPool = async (userId, costPoolId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteCostPool/${userId}/${costPoolId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCostPoolTemplates = async (enterpriseId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetCostPoolTemplates/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFieldHelp = async (enterpriseId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetFieldHelp/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getList = async (userId, listName) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetList/${userId}/${listName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getHolidays = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/General/GetHolidays/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceFlow = async (userId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceFlow/${userId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceFlowContracts = async (userId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceFlow_Contracts/${userId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const copyBudgetToForecast = async (alternativeId, costPoolId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CopyBudgetToForecast/${alternativeId}/${costPoolId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createPlan = async (userId, plan) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreatePlan/${userId}`,
    postRequestOptions(plan)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updatePlan = async (userId, plan) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdatePlan/${userId}`,
    postRequestOptions(plan)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deletePlan = async (userId, planId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeletePlan/${userId}/${planId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPlans = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetPlans/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPlan = async planId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetPlan/${planId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const copyPlan = async (userId, plan) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CopyPlan/${userId}`,
    postRequestOptions(plan)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioList = async (scenarioId, listName) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetList/${scenarioId}/${listName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFieldConfigItems = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetFieldConfigItems/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateFieldConfigItem = async (scenarioId, field) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateFieldConfigItem`,
    postRequestOptions(field)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCostPools = async (enterpriseId, costPoolTemplateId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetCostPools/${enterpriseId}/${costPoolTemplateId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEntitiesByEnterprise = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEntitiesByEnterpriseID/${enterpriseId}/1`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserEntities = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserEntities/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const saveUserEntities = async (userId, entityIdList) => {
  return await fetch(
    `${mainApiUrl}/api/User/SaveUserEntities/${userId}?entityidList=${entityIdList}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioCostPools = async (scenarioId, costPoolTypeId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetCostPools/${scenarioId}/${costPoolTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateCostPool = async (userId, included, costPool) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateCostPool/${userId}/${included}`,
    postRequestOptions(costPool)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseList = async (enterpriseId, listName) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetList/${enterpriseId}/${listName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createEnterpriseListItem = async (enterpriseId, listName, item) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateListItem/${enterpriseId}/${listName}`,
    postRequestOptions(item)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateEnterpriseListItem = async (enterpriseId, listName, item) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateListItem/${enterpriseId}/${listName}`,
    postRequestOptions(item)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteEnterpriseListItem = async (enterpriseId, listName, item) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteListItem/${enterpriseId}/${listName}`,
    deleteRequestOptions(item)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getRoadmaps = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/GetRoadmaps/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getRoadmap = async roadmapId => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/GetRoadmap/${roadmapId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createRoadmap = async (userId, roadmap) => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/CreateRoadmap/${userId}`,
    postRequestOptions(roadmap)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateRoadmap = async (userId, roadmap) => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/UpdateRoadmap/${userId}`,
    postRequestOptions(roadmap)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteRoadmap = async (userId, roadmapId) => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/DeleteRoadmap/${userId}/${roadmapId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createRoadmapAlternative = async (userId, roadmapAlternative) => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/CreateRoadmapAlternative/${userId}`,
    postRequestOptions(roadmapAlternative)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateRoadmapAlternative = async (userId, roadmapAlternative) => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/UpdateRoadmapAlternative/${userId}`,
    postRequestOptions(roadmapAlternative)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteRoadmapAlternative = async (userId, roadmapAlternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Roadmap/DeleteRoadmapAlternative/${userId}/${roadmapAlternativeId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getObjectives = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetObjectives/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const calculateUserPercentages = async (scenarioId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/CalculateUserPercentages/${scenarioId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeRatings = async (
  objectiveId,
  scenarioId,
  show,
  objModelId,
  userId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeRatings/${objectiveId}/${scenarioId}/${show}/${objModelId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcResultsUsers = async (scenarioId, objectiveId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCResults_users/${scenarioId}/${objectiveId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcScoresForUsers = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCScoresForUser/${scenarioId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAIPrompt = async (scope, itemId, field) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetAIPrompt/${scope}/${field}/${itemId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAiSavedPrompt = async (userId, savedPrompt) => {
  return await fetch(
    `${mainApiUrl}/api/General/CreateAISavedPrompt/${userId}`,
    postRequestOptions(savedPrompt)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAiSavedPrompt = async aiSavedPromptId => {
  return await fetch(
    `${mainApiUrl}/api/General/DeleteAISavedPrompt/${aiSavedPromptId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAiSavedPrompts = async (scope, itemId, field) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetSavedAIPrompts/${scope}/${field}/${itemId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeRatingsUsers = async (
  objectiveId,
  scenarioId,
  show,
  pwcUserId,
  altUserId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeRatings_Users/${objectiveId}/${scenarioId}/${show}/${pwcUserId}/${altUserId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourcePlans = async (altId, resourceGroupId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetAlternativeResourcePlans/${altId}/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const updateAlternativeResourcePlan = async (userId, altRgPlan) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateAlternativeResourcePlan/${userId}`,
    postRequestOptions(altRgPlan)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourceBudgets = async (
  altId,
  resourceGroupId,
  costPoolId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetAlternativeResourceBudgets/${altId}/${resourceGroupId}/${costPoolId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const generateAlternativeResourcePlans = async (
  altId,
  rgId,
  rtId,
  units,
  start,
  end
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GenerateAlternativeResourcePlansFromTemplate`,
    postRequestOptions({
      alternativeID: altId,
      resourceGroupID: rgId,
      resourceTemplateID: rtId,
      totalUnits: units,
      startDate: start,
      endDate: end,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourceBudgetsGrid = async (
  altId,
  resourceGroupId,
  costPoolId,
  userId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetAlternativeResourceBudgets_Grid/${altId}/${resourceGroupId}/${costPoolId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourceBudgetsGridBundle = async (
  altId,
  tShirtBundleId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetAlternativeResourceBudgets_Grid_Bundle/${altId}/${tShirtBundleId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourceBudgetsView = async (
  userId,
  altId,
  resourceGroupId,
  bcLineItemId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceBudget_View/${userId}/${altId}/${resourceGroupId}/${bcLineItemId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourceStatus = async (
  userId,
  scenarioId,
  year,
  showExcluded,
  show,
  planbudget
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetAlternativeResourceStatus/${userId}/${scenarioId}/${year}/${showExcluded}/${show}/${planbudget}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCostPoolSummary = async (userId, altId, resourceGroupId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetCostPoolSummary/${userId}/${altId}/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeResourceBudget = async (userId, altRgBudget) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateAlternativeResourceBudget/${userId}`,
    postRequestOptions(altRgBudget)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeResourceFromBudget = async (userId, altRg) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateAlternativeResourceGroup_FromBudget/${userId}`,
    postRequestOptions(altRg)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeResourceGroups = async (altId, resourceGroupId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetAlternativeResourceGroups/${altId}/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeResourceGroup = async (userId, altRgGroup) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateAlternativeResourceGroup/${userId}`,
    postRequestOptions(altRgGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getMeasures = async objectiveId => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetMeasures/${objectiveId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getObjective = async objectiveId => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetObjective/${objectiveId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcScore = async (pwcScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCScore/${pwcScoreId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcScores = async (scenarioId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCScores/${scenarioId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getGPTSummary = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetGPTSummary/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const askOpenAiAssistant = async (prompt, callGeneralAssistant) => {
  return await fetch(
    `${mainApiUrl}/api/OpenAI/AskAssistant`,
    postRequestOptions({
      prompt,
      callGeneralAssistant,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createOpenAiAssistant = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/OpenAI/CreateAssistant/${enterpriseId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScores = async (alternativeId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltScores/${alternativeId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNextAlternativeId = async (alternativeId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetNextAlternativeID/${alternativeId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPrevAlternativeId = async (alternativeId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetPrevAlternativeID/${alternativeId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPrevPwcScore = async (pwcScoreId, levelId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPrevPWCScore/${pwcScoreId}/${levelId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNextPwcScore = async (pwcScoreId, levelId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetNextPWCScore/${pwcScoreId}/${levelId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcScoreGroup = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCScore_Group/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScoreGroup = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltScore_Group/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcUserScores = async (pwcScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCUserScores/${pwcScoreId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcUserScore = async (pwcScoreId, levelId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCUserScore/${pwcScoreId}/${levelId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioInputRatings = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetInputRatings/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getRatingScales = async (scenarioId, scaleType) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetRatingScales/${scenarioId}/${scaleType}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParticipantsByScenarioId = async (scenarioId, includeInactive) => {
  return await fetch(
    `${mainApiUrl}/api/User/GetParticipants/${scenarioId}/${includeInactive}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterprises = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEnterprises/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const processEnterpriseFiles = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/ProcessEnterpriseFiles/${enterpriseId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterprise = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEnterprise/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseByCode = async code => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEnterpriseByCode/${code}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createParticipant = async (updateUserId, participant) => {
  return await fetch(
    `${mainApiUrl}/api/User/CreateParticipant/${updateUserId}`,
    postRequestOptions(participant)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateParticipant = async (updateUserId, participant) => {
  return await fetch(
    `${mainApiUrl}/api/User/UpdateParticipant/${updateUserId}`,
    postRequestOptions(participant)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteParticipant = async (updateUserId, participantId) => {
  return await fetch(
    `${mainApiUrl}/api/User/DeleteParticipant/${updateUserId}/${participantId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createUser = async (updateUserId, user) => {
  return await fetch(
    `${mainApiUrl}/api/User/CreateUser/${updateUserId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateUser = async (updateUserId, user) => {
  return await fetch(
    `${mainApiUrl}/api/User/UpdateUser/${updateUserId}`,
    postRequestOptions(user)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteUser = async (updateUserId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/User/DeleteUser/${updateUserId}/${userId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createObjective = async (updateUserId, objective) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/CreateObjective/${updateUserId}`,
    postRequestOptions(objective)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateObjective = async (updateUserId, objective) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/UpdateObjective/${updateUserId}`,
    postRequestOptions(objective)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteObjective = async (objectiveId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/DeleteObjective/${objectiveId}/${userId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteMeasure = async (measureId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/DeleteMeasure/${measureId}/${userId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteScenario = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteScenario/${userId}/${scenarioId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const scenarioApplyForecastTemplate = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/ApplyForecastTemplate/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const alternativeApplyForecastTemplate = async altId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/ApplyForecastTemplate/${altId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateScenario = async (updateUserId, scenario) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateScenario/${updateUserId}`,
    postRequestOptions(scenario)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createEnterprise = async (updateUserId, enterprise) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateEnterprise/${updateUserId}`,
    postRequestOptions(enterprise)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateEnterprise = async (updateUserId, enterprise) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateEnterprise/${updateUserId}`,
    postRequestOptions(enterprise)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateScenarioListItem = async (scenarioId, listName, listItem) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateListItem/${scenarioId}/${listName}`,
    postRequestOptions(listItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createScenarioListItem = async (scenarioId, listName, listItem) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateListItem/${scenarioId}/${listName}`,
    postRequestOptions(listItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteScenarioListItem = async (listItemId, listName) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteListItem/${listItemId}/${listName}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createScenario = async (updateUserId, scenario) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateScenario/${updateUserId}`,
    postRequestOptions(scenario)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createMeasure = async (updateUserId, measure) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/CreateMeasure/${updateUserId}`,
    postRequestOptions(measure)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateMeasure = async (updateUserId, measure) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/UpdateMeasure/${updateUserId}`,
    postRequestOptions(measure)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenario = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetScenario/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNotificationEmails = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Notification/GetNotificationEmails/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateNotificationEmail = async (userId, notificationEmail) => {
  return await fetch(
    `${mainApiUrl}/api/Notification/UpdateNotificationEmail/${userId}`,
    postRequestOptions(notificationEmail)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const sendInAppNotification = async inAppNotification => {
  return await fetch(
    `${mainApiUrl}/api/Notification/SendInAppNotification`,
    postRequestOptions(inAppNotification)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenariosByUser = async (userId, scenarioTypeId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetScenariosByUser/${userId}/${scenarioTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPrograms = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetPrograms/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFunds = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetFunds/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createPrograms = async (userId, program) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateProgram/${userId}`,
    postRequestOptions(program)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updatePrograms = async (userId, program) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateProgram/${userId}`,
    postRequestOptions(program)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteProgram = async programId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteProgram/${programId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createFund = async (userId, fund) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateFund/${userId}`,
    postRequestOptions(fund)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateFund = async (userId, fund) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateFund/${userId}`,
    postRequestOptions(fund)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteFund = async fundId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteFund/${fundId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updatePwcUserScore = async (levelId, pwcScoreId, pwcUserScore) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/UpdatePWCUserScore/${levelId}/${pwcScoreId}`,
    postRequestOptions(pwcUserScore)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAltUserScore = async (levelId, altUserScore) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAltUserScore/${levelId}`,
    postRequestOptions(altUserScore)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternative = async (userId, alternative) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateAlternative/${userId}`,
    postRequestOptions(alternative)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternative = async (userId, alternative) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAlternative/${userId}`,
    postRequestOptions(alternative)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const lockAlternative = async (alternativeId, lockUnlock) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/LockAlternative/${alternativeId}/${lockUnlock}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const clearAlternativeForecast = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/ClearForecast/${alternativeId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deletePwcUserScore = async (userId, pwcScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/DeleteUserPWCScore/${userId}/${pwcScoreId}/${levelId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deletePwcUserScoresScenario = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/DeleteUserPWCScores_Scenario/${userId}/${scenarioId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteUserAltScores = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteUserAltScores/${userId}/${scenarioId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAltUserScore = async (userId, altScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteUserAltScore/${userId}/${altScoreId}/${levelId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const recalculateObjective = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/Recalculate/${userId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const resetObjectiveScores = async (scenarioId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/ResetScores/${scenarioId}/${userId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const recalculateAlternative = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/RecalculateAlternativeScores/${userId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const resetAlternativeScores = async (scenarioId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/ResetScores/${scenarioId}/${userId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const resetAlternativeScoreSingle = async (
  scenarioId,
  userId,
  altScoreId,
  levelId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/ResetScores_Single/${scenarioId}/${userId}/${altScoreId}/${levelId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const setPreferenceWeightsToAHP = async (scenarioId, objectiveId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/SetPreferenceWeightsToAHP/${scenarioId}/${objectiveId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScore = async (altScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltScore/${altScoreId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScoreConsensus = async (objectiveId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltScoreConsensus/${objectiveId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltUserScores = async (altScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltUserScores/${altScoreId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltUserScore = async (altScoreId, levelId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltUserScore/${altScoreId}/${levelId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPrevAltScore = async (altScoreId, levelId, scenarioId, objOrAlt) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetPrevAltScore/${altScoreId}/${levelId}/${scenarioId}/${objOrAlt}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNextAltScore = async (altScoreId, levelId, scenarioId, objOrAlt) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetNextAltScore/${altScoreId}/${levelId}/${scenarioId}/${objOrAlt}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternatives = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives/${userId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesUserHasRole = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativesUserHasRole/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesForAccess = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_ForAccess/${userId}/${scenarioId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeFlowThrough = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAlternativeFlowThrough/${alternativeId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const saveUserAccessAlternatives = async (userId, alternativeIDList) => {
  return await fetch(
    `${mainApiUrl}/api/User/SaveUserAccessAlternatives/${userId}`,
    postRequestOptions({ alternativeIds: alternativeIDList })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserPhotoBase64 = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserPhotoBase64/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateUserPhotoBase64 = async (userId, base64) => {
  return await fetch(
    `${mainApiUrl}/api/User/UpdateUserPhotoBase64/${userId}`,
    postRequestOptions({ base64: base64 })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserAccessAlternatives = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserAcccessAlternatives/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesByUser = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativesByUser/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getIdeasByUser = async (userId, forRoadmap) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetIdeasByUser/${userId}/${forRoadmap}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesSearch = async (userId, scenarioId, search) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_Search/${scenarioId}/${userId}?search=${search}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesForCharts = async (
  scenarioId,
  objId,
  objModelId,
  finModelId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativesForCharts/${scenarioId}/${objId}/${objModelId}/${finModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesDecision = async (
  scenarioId,
  objModelId,
  finModelId,
  showExcluded
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_Decision/${scenarioId}/${objModelId}/${finModelId}/${showExcluded}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesPlan = async (scenarioId, planId, showExcluded) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_Plan/${scenarioId}/${planId}/${showExcluded}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const addUpdatePlanAlternative = async (userId, planAlternative) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/AddUpdatePlanAlternative/${userId}`,
    postRequestOptions(planAlternative)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScoresSecondary = async (
  scenarioId,
  objModelId,
  finModelId,
  decision
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeScoresWSecondary/${scenarioId}/${objModelId}/${finModelId}/${decision}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternative = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternative/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBCCostPools = async (alternativeId, costBenefit, nonZero, useRates) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetBCCostPools/${alternativeId}/${costBenefit}/${nonZero}/${useRates}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBCSummary = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetBCSummary/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateBCCostPool = async (userId, costPool) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateBCCostPool/${userId}`,
    postRequestOptions(costPool)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeMetrics = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAlternativeMetrics/${alternativeId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeForecastSummary = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetForecastSummary/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeTabs = async (userId, scenarioId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetTabs/${userId}/${scenarioId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesByParams = async (userId, scenarioId, params) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_params/${userId}/${scenarioId}`,
    postRequestOptions(params)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getAlternativesByUserParams = async (userId, params) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativesByUser/${userId}`,
    postRequestOptions(params)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getConfigItems = async (userId, scenarioId, configType) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetConfigItems/${userId}/${scenarioId}/${configType}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateConfigItem = async (userId, scenarioId, configItem) => {
  return await fetch(
    `${mainApiUrl}/api/User/UpdateConfigItem/${userId}/${scenarioId}`,
    postRequestOptions(configItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeListFilters = async alternativeListId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeListFilters/${alternativeListId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeListFilter = async (alternativeListId, configItem) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAlternativeListFilter/${alternativeListId}`,
    postRequestOptions(configItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScoreByAltId = async (
  scenarioId,
  alternativeId,
  objMeasId,
  levelId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAltScore_byAltID/${scenarioId}/${alternativeId}/${objMeasId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPwcConsensus = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Objective/GetPWCConsensus/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBcTabOptions = async (scenarioId, tab) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetBCTabOptions/${scenarioId}/${tab}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const uploadEnterpriseLogo = async (enterpriseId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UploadLogo/${enterpriseId}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
      },
      body: data,
    }
  )
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const getPageItem = (userId, scenarioId, alternativeId, view) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${mainApiUrl}/api/General/GetPageItem/${userId}/${scenarioId}/${alternativeId}/${view}`,
      getRequestOptions()
    )
      .then(handleResponse)
      .then(
        data => {
          resolve(data)
        },
        error => {
          reject(error.message)
        }
      )
  })
}

const getContractForecast = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractForecast/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractCompare = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractCompare/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractComparePayment = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractCompare_Payment/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractsForResource = async (scenarioId, resourceGroupId) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractsForResource/${resourceGroupId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractsForEnterprise = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractsForEnterprise/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractsForScenario = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractsForScenario/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractsCostPools = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetBCContractCostPools/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupsByBcCostPool = async bcCostPoolId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetResourceGroupsByBCCostPool/${bcCostPoolId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupTShirts = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupTShirts/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractEntities = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractEntities/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioContractEntities = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetContractEntities/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getRemainingContractAmounts = async bcCostPoolId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetRemainingContractAmounts/${bcCostPoolId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getContractItems = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetContractItems/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createContractItem = async (userId, contract) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/CreateContractItem/${userId}`,
    postRequestOptions(contract)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createContract = async (userId, contract) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/CreateContract/${userId}`,
    postRequestOptions(contract)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateContract = async (userId, contract) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/UpdateContract/${userId}`,
    postRequestOptions(contract)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateContractItem = async (userId, contract) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/UpdateContractItem/${userId}`,
    postRequestOptions(contract)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteContractItem = async (userId, contractItemId) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/DeleteContractItem/${userId}/${contractItemId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteContract = async (userId, contractId) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/DeleteContract/${userId}/${contractId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseResourceChartScenario = async (
  period,
  startdate,
  enddate,
  scenarioList,
  decisionId,
  resourceClassId,
  resourceGroupId
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetEnterpriseResourceChart_Scenario`,
    postRequestOptions({
      period: period,
      startDate: startdate,
      endDate: enddate,
      scenarioList: scenarioList,
      decisionID: decisionId,
      resourceClassID: resourceClassId,
      resourceGroupID: resourceGroupId,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesBarChart = async (
  scenarioId,
  objId,
  altList,
  objModelId
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetAlternativeBarChart`,
    postRequestOptions({
      scenarioID: scenarioId,
      objectiveID: objId,
      alternativeList: altList,
      objModelID: objModelId,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesFinancialChart = async payload => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetAlternativeFinancialChart`,
    postRequestOptions({ ...payload })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserChartDrilldown = async (userChartId, item) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetUserChartDrilldown/${userChartId}/${item}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesBubbleChart = async (
  scenarioId,
  objModelId,
  finModelId,
  altList,
  xAxis,
  showNames,
  chartOption
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetAlternativeBubbleChart`,
    postRequestOptions({
      scenarioID: scenarioId,
      alternativeList: altList,
      objModelID: objModelId,
      finModelID: finModelId,
      xaxis: xAxis,
      showNames: showNames,
      chartOption: chartOption,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getObjRadarChart = async (
  scenarioId,
  objModelId,
  weighted,
  alternativeList
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetObjRadarChart`,
    postRequestOptions({
      scenarioID: scenarioId,
      weighted: weighted,
      alternativeList: alternativeList,
      objModelID: objModelId,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getRoadmapChart = async (roadmapId, chartName) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetChart_Roadmap/${roadmapId}/${chartName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativePhasesChart = async (scenarioId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetChart_AlternativePhases/${scenarioId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativePhasesCostChart = async (scenarioId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetChart_AlternativePhases_Cost/${scenarioId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltH2hChart = async (scenarioId, alt1Id, alt2Id, objModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetAltH2HChart/${scenarioId}/${alt1Id}/${alt2Id}/${objModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltScatterChart = async (
  scenarioId,
  objModelId,
  finModelId,
  alternativeList,
  xAxis,
  showNames
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetAlternativeScatterChart`,
    postRequestOptions({
      scenarioID: scenarioId,
      alternativeList: alternativeList,
      objModelID: objModelId,
      finModelID: finModelId,
      xaxis: xAxis,
      shownames: showNames,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseResourceChartClass = async (
  period,
  startdate,
  enddate,
  scenarioList,
  decisionId,
  resourceClassId
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetEnterpriseResourceChart_Class`,
    postRequestOptions({
      period: period,
      startDate: startdate,
      endDate: enddate,
      scenarioList: scenarioList,
      decisionID: decisionId,
      resourceClassID: resourceClassId,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseResourceChartResource = async (
  period,
  startdate,
  enddate,
  scenarioList,
  decisionId,
  resourceClassId
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetEnterpriseResourceChart_Resource`,
    postRequestOptions({
      period: period,
      startDate: startdate,
      endDate: enddate,
      scenarioList: scenarioList,
      decisionID: decisionId,
      resourceClassID: resourceClassId,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getMixChart = async (optModelId, objModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetMixChart/${optModelId}/${objModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceHeatmap = async (
  period,
  start,
  end,
  scenId,
  altList,
  yearId,
  monthId,
  resourceList,
  aggregate
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetResourceHeatmap`,
    postRequestOptions({
      period: period,
      startDate: start,
      endDate: end,
      alternativeList: altList,
      scenarioID: scenId,
      yearID: yearId,
      monthID: monthId,
      resourceList: resourceList,
      aggregate: aggregate,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceDrilldown = async (
  period,
  start,
  end,
  scenId,
  altList,
  yearId,
  monthId,
  rgId,
  resourceList,
  aggregate
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetResourceDrillDown`,
    postRequestOptions({
      period: period,
      startDate: start,
      endDate: end,
      alternativeList: altList,
      scenarioID: scenId,
      yearID: yearId,
      monthID: monthId,
      resourceGroupID: rgId,
      resourceList: resourceList,
      aggregate: aggregate,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceColumnChart = async (
  scenarioId,
  start,
  end,
  altList,
  resourceList
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetResourceColumnChart`,
    postRequestOptions({
      scenarioID: scenarioId,
      startDate: start,
      endDate: end,
      alternativeList: altList,
      resourceList: resourceList,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceAreaRgChart = async (
  scenarioId,
  start,
  end,
  altList,
  resourceList
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetResourceArea_ByRG`,
    postRequestOptions({
      scenarioID: scenarioId,
      startDate: start,
      endDate: end,
      alternativeList: altList,
      resourceList: resourceList,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceAreaAltChart = async (
  scenarioId,
  start,
  end,
  altList,
  resourceList,
  aggregate
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetResourceArea_ByAlt`,
    postRequestOptions({
      scenarioID: scenarioId,
      startDate: start,
      endDate: end,
      alternativeList: altList,
      resourceList: resourceList,
      aggregate: aggregate,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getMixChartMulti = async optParetoItemId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetMixChart_Multi/${optParetoItemId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getMixChartPlan = async (planId, scenarioId, prisec) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetMixChart_Plan/${planId}/${scenarioId}/${prisec}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getParetoChart = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetParetoChart/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeChart = async (alternativeId, name) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetChart_Alternative/${alternativeId}/${name}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeListChart = async (
  altList,
  chartName,
  typeId,
  scenarioId
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetChart_AlternativeList`,
    postRequestOptions({
      alternativeList: altList,
      chartName: chartName,
      scenarioID: scenarioId,
      typeID: typeId,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFinancingSummary = async (alternativeId, bcRateId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetFinancingSummary/${alternativeId}/${bcRateId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeLocation = async (userId, location) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateLocation/${userId}`,
    postRequestOptions(location)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeLocation = async (userId, location) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateLocation/${userId}`,
    postRequestOptions(location)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativeLocation = async locationId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteLocation/${locationId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeLookbacks = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetLookbacks/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const applyAlternativeCostPools = async (alternativeId, bcTemplateId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/ApplyAlternativeCostPools/${alternativeId}/${bcTemplateId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getLog = async (userId, logModel) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetLog/${userId}`,
    postRequestOptions(logModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioGroups = async () => {
  return await fetch(
    `${mainApiUrl}/api/ScenarioGroup/GetScenarioGroups`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createScenarioGroup = async scenarioGroup => {
  return await fetch(
    `${mainApiUrl}/api/ScenarioGroup/CreateScenarioGroup`,
    postRequestOptions(scenarioGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateScenarioGroup = async scenarioGroup => {
  return await fetch(
    `${mainApiUrl}/api/ScenarioGroup/UpdateScenarioGroup`,
    postRequestOptions(scenarioGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteScenarioGroup = async scenarioGroupId => {
  return await fetch(
    `${mainApiUrl}/api/ScenarioGroup/DeleteScenarioGroup/${scenarioGroupId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativePhases = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetAlternativePhases/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativePhase = async (userId, phase) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateAlternativePhase/${userId}`,
    postRequestOptions(phase)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativePhase = async (userId, phase) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateAlternativePhase/${userId}`,
    postRequestOptions(phase)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativePhase = async (userId, alternativePhaseId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteAlternativePhase/${userId}/${alternativePhaseId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeSensitivity = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeSensitivity/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const sendAltResourceBudgetEmail = async (userId, altId, text, userIdList) => {
  return await fetch(
    `${mainApiUrl}/api/Email/SendAlternativeResourceBudgetEmail`,
    postRequestOptions({
      userID: userId,
      alternativeID: altId,
      reminderText: text,
      userIDList: userIdList,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const resetObjectiveSensitivity = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Objective/ResetSensitivity/${scenarioId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeLookback = async lookbackId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetLookback/${lookbackId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeLookback = async (userId, lookback) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateLookback/${userId}`,
    postRequestOptions(lookback)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeLookback = async (userId, lookback) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateLookback/${userId}`,
    postRequestOptions(lookback)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativeLookback = async (userId, lookbackId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteLookback/${userId}/${lookbackId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeLocations = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetLocations/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesLocations = async alternativeIds => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetLocations_AlternativeList`,
    postRequestOptions({
      alternativeIds: alternativeIds,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createEnterpriseBcRate = async (userId, bcRate) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateBcRate/${userId}`,
    postRequestOptions(bcRate)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateEnterpriseBcRate = async (userId, bcRate) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateBcRate/${userId}`,
    postRequestOptions(bcRate)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteEnterpriseBcRate = async (userId, bcRateId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateBcRate/${userId}/${bcRateId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const addOrUpdateEnterpriseBcRateYear = async (userId, bcRateYear) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/AddOrUpdateBcRateYear/${userId}`,
    postRequestOptions(bcRateYear)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseBcRates = async (enterpriseId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetBcRates/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseBcRateYears = async (enterpriseId, fromYear, toYear) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetBcRateYears/${enterpriseId}/${fromYear}/${toYear}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEnterpriseLabel = async (enterpriseId,label) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetLabel/${enterpriseId}?label=${label}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskLog = async (scope, itemId,userId,taskTypeId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskLog/${scope}/${itemId}/${userId}/${taskTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const checkTaskOwner = async (userId,taskId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CheckTaskOwner/${userId}/${taskId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getLocation = async locationId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetLocation/${locationId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserAlternativeAccessData = async (scenarioId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/User/GetUserAlternativeAccessData/${scenarioId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateUserAlternativeAccessData = async (
  userId,
  alternativeId,
  hasAccess
) => {
  return await fetch(
    `${mainApiUrl}/api/User/UpdateUserAccess_Alternative/${userId}/${alternativeId}/${hasAccess}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTasks = async (scenarioId, alternativeId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTasks/${scenarioId}/${alternativeId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getKeyTasks = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetKeyTasks/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTasksUserHasRole = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTasksUserHasRole/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskTypeFieldOptions = async () => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskTypeFieldOptions`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskTypeFields = async (taskTypeId, enterpriseId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskTypeFields/${taskTypeId}/${enterpriseId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createTaskType = async (userId, payload) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateTaskType/${userId}`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateTaskType = async (userId, payload) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateTaskType/${userId}`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteTaskType = async (userId, taskTypeId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteTaskType/${userId}/${taskTypeId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskTypeGeneralListItems = async (
  enterpriseId,
  scenarioId,
  listName
) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskTypeNonEditableList/${enterpriseId}/${scenarioId}?listName=${listName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskTypeListItems = async (taskTypeId, listName) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskTypeListItems/${taskTypeId}?listName=${listName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskListWorkflows = async (scenarioId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskListWorkflows/${scenarioId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeTaskList = async (alternativeId, workflowId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateAlternativeTaskList/${alternativeId}/${workflowId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativeTaskList = async (alternativeId, workflowId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteAlternativeTaskList/${alternativeId}/${workflowId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getWorkflow = async workflowId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetWorkflow/${workflowId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getWorkflowActions = async workflowTaskTypeId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetWorkflowActions/${workflowTaskTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createWorkflowAction = async (userId, workflowAction) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateWorkflowAction/${userId}`,
    postRequestOptions(workflowAction)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateWorkflowAction = async (userId, workflowAction) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateWorkflowAction/${userId}`,
    postRequestOptions(workflowAction)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteWorkflowAction = async (userId, workflowActionId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteWorkflowAction/${userId}/${workflowActionId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getWorkflowTaskTypes = async workflowId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetWorkflowTaskTypes/${workflowId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createWorkflowTaskType = async (userId, workflowTaskType) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateWorkflowTaskType/${userId}`,
    postRequestOptions(workflowTaskType)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateWorkflowTaskType = async (userId, workflowTaskType) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateWorkflowTaskType/${userId}`,
    postRequestOptions(workflowTaskType)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteWorkflowTaskType = async (userId, workflowTaskTypeId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteWorkflowTaskType/${userId}/${workflowTaskTypeId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createWorkflow = async (userId, workflow) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateWorkflow/${userId}`,
    postRequestOptions(workflow)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateWorkflow = async (userId, workflow) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateWorkflow/${userId}`,
    postRequestOptions(workflow)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteWorkflow = async (userId, workflowId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteWorkflow/${userId}/${workflowId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createTaskTypeListItem = async (taskTypeId, listName, listItem) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateTaskTypeListItem/${taskTypeId}?listName=${listName}`,
    postRequestOptions(listItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateTaskTypeListItem = async (taskTypeId, listName, listItem) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateTaskTypeListItem/${taskTypeId}?listName=${listName}`,
    postRequestOptions(listItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteTaskTypeListItem = async (taskTypeId, listName, listItemId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteTaskTypeListItem/${taskTypeId}/${listItemId}?listName=${listName}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createTaskTypeField = async (userId, payload) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateTaskTypeField/${userId}`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateTaskTypeField = async (userId, payload) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateTaskTypeField/${userId}`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteTaskTypeField = async (userId, taskTypeFieldId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteTaskTypeField/${userId}/${taskTypeFieldId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getGeneralTasks = async (taskTypeId, scope, itemId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetGeneralTasks/${scope}/${itemId}/${userId}/${taskTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTask = async taskId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTask/${taskId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskList = async (taskTypeId, listName) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetList/${taskTypeId}/${listName}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskFields = async taskTypeId => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskType/${taskTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createTask = async (userId, task) => {
  return await fetch(
    `${mainApiUrl}/api/Task/CreateTask/${userId}`,
    postRequestOptions(task)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateTask = async (userId, task) => {
  return await fetch(
    `${mainApiUrl}/api/Task/UpdateTask/${userId}`,
    postRequestOptions(task)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskType = async (taskTypeId, enterpriseId = 0, scenarioId = 0) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskType/${taskTypeId}/${enterpriseId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getTaskTypes = async (scenarioId = 0) => {
  return await fetch(
    `${mainApiUrl}/api/Task/GetTaskTypes/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioTaskTypes = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetScenarioTaskTypes/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateScenarioTaskTypes = async (scenarioId, scenarioTaskTypes) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateScenarioTaskTypes/${scenarioId}`,
    postRequestOptions(scenarioTaskTypes)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteTask = async (userId, taskId) => {
  return await fetch(
    `${mainApiUrl}/api/Task/DeleteTask/${userId}/${taskId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNotes = async (alternativeId, reviewerRoleId, type) => {
  return await fetch(
    `${mainApiUrl}/api/Note/GetNotes/${alternativeId}/${reviewerRoleId}/${type}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNoteReviewSummary = async (alternativeId, showAll) => {
  return await fetch(
    `${mainApiUrl}/api/Note/GetNoteReviewSummary/${alternativeId}/${showAll}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createNote = async (userId, note) => {
  return await fetch(
    `${mainApiUrl}/api/Note/CreateNote/${userId}`,
    postRequestOptions(note)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateNote = async (userId, note) => {
  return await fetch(
    `${mainApiUrl}/api/Note/UpdateNote/${userId}`,
    postRequestOptions(note)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteNote = async (userId, noteId) => {
  return await fetch(
    `${mainApiUrl}/api/Note/DeleteNote/${userId}/${noteId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getDocuments = async (alternativeId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetDocs/${alternativeId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createDocument = async (userId, file, document) => {
  var data = new FormData()
  data.append("file", file)
  data.append("document", JSON.stringify(document))
  return await fetch(`${mainApiUrl}/api/Alternative/CreateDoc/${userId}`, {
    method: "POST",
    headers: {
      Authorization: getToken(),
    },
    body: data,
  })
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const getGptDocuments = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetGPTDocs/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const uploadGptDocument = async (enterpriseId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UploadGptDoc/${enterpriseId}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
      },
      body: data,
    }
  )
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const deleteGptDocument = async gptDocId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteGptDoc/${gptDocId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createEntityDocument = async (userId, file, document) => {
  var data = new FormData()
  data.append("file", file)
  data.append("document", JSON.stringify(document))
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateEntityDocument/${userId}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
      },
      body: data,
    }
  )
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const uploadTaskDocument = async (taskId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(`${mainApiUrl}/api/Task/UploadTaskDocument/${taskId}`, {
    method: "POST",
    headers: {
      Authorization: getToken(),
    },
    body: data,
  })
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const deleteEntityDocument = async entityDocId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteEntityDocument/${entityDocId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateDocument = async (userId, document) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateDoc/${userId}`,
    postRequestOptions(document)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteDocument = async (alternativeId, documentId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteDoc/${alternativeId}/${documentId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getWidgetsByView = async (view, id) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetWidgetsByView/${view}/${id}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getWidget = async (widgetId, itemId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetWidget/${widgetId}/${itemId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltObjModels = async (scenarioId, show) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_ObjModels/${scenarioId}/${show}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltFinModels = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_FinModels/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltOptModels = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_OptModels/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAltAllModels = async (scenarioId, show) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternatives_All/${scenarioId}/${show}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getYears = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetYears/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserCharts = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetUserCharts/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getUserChart = async chartId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetUserChart/${chartId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getGeneralChart = async (chartId, itemId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetGeneralChart/${chartId}/${itemId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const updateUserChart = async (userId, chartData) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/UpdateUserChart/${userId}`,
    postRequestOptions(chartData)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const createUserChart = async (userId, chartData) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CreateUserChart/${userId}`,
    postRequestOptions(chartData)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const copyUserChart = async (userId, userChartId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CopyUserChart/${userId}/${userChartId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const deleteUserChart = async (userId, userChartId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/DeleteUserChart/${userId}/${userChartId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getInfoSection = async infoSectionId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetInfoSection/${infoSectionId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getInfoSections = async (itemId, scope) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetInfoSections/${itemId}/${scope}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const createInfoSection = async (userId, infoSection) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CreateInfoSection/${userId}`,
    postRequestOptions(infoSection)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const updateInfoSection = async (userId, infoSection) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/UpdateInfoSection/${userId}`,
    postRequestOptions(infoSection)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const deleteInfoSection = async infoSectionId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/DeleteInfoSection/${infoSectionId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getDashboards = async (userId, includeShared) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetDashboards/${userId}/${includeShared}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getDashboard = async dashboardId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetDashboard/${dashboardId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getDashboardScenario = async (dashboardId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetDashboard/${dashboardId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getUserChartsForUser = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetUserCharts_ForUser/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getDashboardItemsForUser = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetDashboardItems_ForUser/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateDashboard = async (userId, dashboard) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/UpdateDashboard/${userId}`,
    postRequestOptions(dashboard)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const createDashboard = async (userId, dashboard) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CreateDashboard/${userId}`,
    postRequestOptions(dashboard)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const copyDashboard = async (userId, dashboardId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CopyDashboard/${userId}/${dashboardId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const deleteDashboard = async (userId, dashboardId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/DeleteDashboard/${userId}/${dashboardId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBcReportOptions = async (altId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Report/GetBCReportOptions/${altId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBcReportScenarioOptions = async (scenarioId, userId) => {
  return await fetch(
    `${mainApiUrl}/api/Report/GetBCReportOptions_Scenario/${scenarioId}/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBcReportSets = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetBCReportSets/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBcReportSets_CanView = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/getBcReportSets_CanView/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBcReportSet = async reportSetId => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetBCReportSet/${reportSetId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getBcFields = async (
  userId,
  fieldTypeId,
  bcTemplateId,
  costPoolTypeId,
  costPoolId,
  yearId
) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/GetBCFields/${userId}/${fieldTypeId}/${bcTemplateId}/${costPoolTypeId}/${costPoolId}/${yearId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceClassesByGroup = async resourceGroupId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceClassesByGroup/${resourceGroupId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceEstimateLog = async (
  alternativeId,
  resourceGroupId,
  costPoolId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceEstimateLog/${alternativeId}/${resourceGroupId}/${costPoolId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createResourceGroupClass = async (
  userId,
  resourceGroupId,
  resourceClassId
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/CreateResourceGroupClass/${userId}/${resourceGroupId}/${resourceClassId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateResourceGroupClasses = async (
  userId,
  resourceGroupId,
  resourceClassIdList
) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateResourceGroupClass/${userId}/${resourceGroupId}?resourceClassIdList=${resourceClassIdList}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateResourceGroupTShirts = async (userId, resourceGroupId, tshirts) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/UpdateResourceGroupTShirts/${userId}/${resourceGroupId}`,
    postRequestOptions(tshirts)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteResourceGroupClass = async (userId, resourceGroupClassId) => {
  return await fetch(
    `${mainApiUrl}/api/Resource/DeleteResourceGroupClass/${userId}/${resourceGroupClassId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const createCalcField = async (userId, calcField) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateCalcField/${userId}`,
    postRequestOptions(calcField)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const updateCalcField = async (userId, calcField) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateCalcField/${userId}`,
    postRequestOptions(calcField)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const deleteCalcField = async calcFieldId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/DeleteCalcField/${calcFieldId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativeList = async alternativeListId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteAlternativeList/${alternativeListId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateBcReportSet = async (userId, set) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/UpdateBCReportSet/${userId}`,
    postRequestOptions(set)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const createBcReportSet = async (userId, set) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CreateBCReportSet/${userId}`,
    postRequestOptions(set)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const copyBcReportSet = async (userId, bcReportSetId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/CopyBCReportSet/${userId}/${bcReportSetId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const deleteBcReportSet = async (userId, bcReportSetId) => {
  return await fetch(
    `${mainApiUrl}/api/Chart/DeleteBCReportSet/${userId}/${bcReportSetId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

//exportType: 0-Excel, 1-DataTable
const exportViewReport = async (viewId, scenarioId, mode, exportType) => {
  if (exportType == 0) {
    return fetch(
      `${mainApiUrl}/api/Export/ExportView/${viewId}/${scenarioId}/${mode}?exportType=${exportType}`,
      getRequestOptions()
    )
      .then(resp => {
        return resp.blob()
      })
      .then(blob => {
        return blob
      }, handleError)
  }

  return await fetch(
    `${mainApiUrl}/api/Export/ExportView/${viewId}/${scenarioId}/${mode}?exportType=${exportType}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

//exportType: 0-Excel, 1-DataTable
const exportViewReportByAlternatives = async (
  viewId,
  altIdsStringList,
  mode,
  exportType
) => {
  if (exportType == 0) {
    return fetch(
      `${mainApiUrl}/api/Export/ExportView_ByAlt/${viewId}/${mode}?exportType=${exportType}`,
      postRequestOptions({
        alternativeIds: altIdsStringList,
      })
    )
      .then(resp => {
        return resp.blob()
      })
      .then(blob => {
        return blob
      }, handleError)
  }

  return await fetch(
    `${mainApiUrl}/api/Export/ExportView_ByAlt/${viewId}/${mode}?exportType=${exportType}`,
    postRequestOptions({
      alternativeIds: altIdsStringList,
    })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getReport = async reportId => {
  return await fetch(
    `${mainApiUrl}/api/Report/GetReport/${reportId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const runTelerikUserReport = (telerikUserReportId, pdfOrExcel) => {
  return fetch(
    `${mainApiUrl}/api/Report/RunTelerikUserReport/${telerikUserReportId}?pdfOrExcel=${pdfOrExcel}`,
    getRequestOptions()
  )
    .then(resp => {
      return resp.blob()
    })
    .then(blob => {
      return blob
    }, handleError)
}
const runReport = reportData => {
  return fetch(
    `${mainApiUrl}/api/Report/RunReport`,
    postRequestOptions(reportData)
  )
    .then(resp => {
      return resp.blob()
    })
    .then(blob => {
      return blob
    }, handleError)
}

const getAlternativeWordDocument = alternativeId => {
  return fetch(
    `${mainApiUrl}/api/Document/GetWordDocument/${alternativeId}`,
    getRequestOptions()
  )
    .then(resp => {
      return resp.blob()
    })
    .then(blob => {
      return blob
    }, handleError)
}

const getPowerPointDocument = alternativeId => {
  return fetch(
    `${mainApiUrl}/api/Document/GetPpt/${alternativeId}`,
    getRequestOptions()
  )
    .then(resp => {
      return resp.blob()
    })
    .then(blob => {
      return blob
    }, handleError)
}

const downloadBusinessCaseReport = reportData => {
  return fetch(
    `${mainApiUrl}/api/Report/GetBCReportsArchiveR2`,
    postRequestOptions(reportData)
  )
    .then(resp => {
      return resp.blob()
    })
    .then(blob => {
      return blob
    }, handleError)
}

const getUploaderFileLink = fileType => {
  return fetch(
    `${mainApiUrl}/api/General/GetUploaderFileLink/${fileType}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBusinessCaseReport = (userId, bcId, options) => {
  let url = `${mainApiUrl}/api/Report/GetBCReport/${userId}/${bcId}`
  for (let i = 0; i < options.length; i++) {
    let separator = i == 0 ? "?" : "&"
    url +=
      separator +
      options[i].listItemCategory +
      "=" +
      (options[i].listItemID == 1 ? true : false)
  }
  return fetch(url, getRequestOptions())
    .then(resp => {
      return resp.blob()
    })
    .then(blob => {
      return blob
    }, handleError)
}

const getBusinessCase = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetBusinessCase/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeByBusinessCaseId = async bcId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeByBCID/${bcId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getCalcFields = async userId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetCalcFields/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEntities = async (userId, entityTypeId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEntities/${userId}/${entityTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getEntityFields = async entityTypeId => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEntityFields/${entityTypeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getEntityFieldData = async (entityId, yearId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEntityFieldData/${entityId}/${yearId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateEntity = async (userId, entity) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/UpdateEntity/${userId}`,
    postRequestOptions(entity)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const addUpdateEntityFieldData = async (userId, entityFieldData) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/AddUpdateEntityFieldData/${userId}`,
    postRequestOptions(entityFieldData)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}
const getEntityDocs = async (entityId, yearId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEntityDocs/${entityId}/${yearId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createEntity = async (userId, entity) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/CreateEntity/${userId}`,
    postRequestOptions(entity)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeScorecardItems = async alternativeID => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetScorecardItems/${alternativeID}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScorecardItemsByScorecard = async scorecard => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetScorecardItemsByScorecard?scorecard=${scorecard}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const addUpdateScorecardItem = async (userId, alternativeId, scorecardItem) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/AddUpdateScorecardItem/${userId}/${alternativeId}`,
    postRequestOptions(scorecardItem)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNmhed = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetNMHED/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createNmhed = async (userId, scenarioId, nmhed) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateNMHED/${userId}/${scenarioId}`,
    postRequestOptions(nmhed)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateNmhed = async (userId, nmhed) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateNMHED/${userId}`,
    postRequestOptions(nmhed)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getEntitiesByParent = async (userId, parentEntityId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetEntitiesByParent/${userId}/${parentEntityId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNmhedFteData = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetNMHEDFTEData/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNmStatuses = async (userId, alternativeId) => {
  return await fetch(
    `${mainApiUrl}/api/Enterprise/GetNMStatuss/${userId}/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNotificationRules = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetNotificationRules/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNotificationRuleFields = async (scenarioId, userId, item) => {
  return await fetch(
    `${mainApiUrl}/api/User/GetNotificationRuleFields/${scenarioId}/${userId}/${item}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getNotificationTriggers = async (scenarioId, triggerType) => {
  return await fetch(
    `${mainApiUrl}/api/User/GetNotificationTriggers/${scenarioId}/${triggerType}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createNotificationRule = async (userId, rule) => {
  return await fetch(
    `${mainApiUrl}/api/User/CreateNotificationRule/${userId}`,
    postRequestOptions(rule)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateNotificationRule = async (userId, rule) => {
  return await fetch(
    `${mainApiUrl}/api/User/UpdateNotificationRule/${userId}`,
    postRequestOptions(rule)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteNotificationRule = async (userId, ruleId) => {
  return await fetch(
    `${mainApiUrl}/api/User/DeleteNofiticationRule/${userId}/${ruleId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOtherPortfolioUsers = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/User/GetOtherPortfolioUsers/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createOrUpdateScenarioTabSection = async (
  scenarioId,
  formId,
  tabName
) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateOrUpdateScenarioTabSection/${scenarioId}/${formId}?tabName=${tabName}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenarioTabSections = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetScenarioTabSections/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getScenariosRollup = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetScenarios_Rollup/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteScenarioTabSection = async scenarioTabSectionId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteScenarioTabSection/${scenarioTabSectionId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const copyScenario = async payload => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CopyScenario_2`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeLists = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeLists/${userId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const checkUserLinkAccess = async (userId, itemId, itemType) => {
  return await fetch(
    `${mainApiUrl}/api/User/CheckUserLinkAccess/${userId}/${itemId}/${itemType}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeList = async (userId, altList) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateAlternativeList/${userId}`,
    postRequestOptions(altList)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeListAlternative = async (
  alternativeListId,
  alternativeId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateAlternativeListAlternative/${alternativeListId}/${alternativeId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativeListAlternative = async (
  alternativeListId,
  alternativeId
) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteAlternativeListAlternative/${alternativeListId}/${alternativeId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativesByList = async (userId, scenarioId, altListId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativesByList/${userId}/${scenarioId}/${altListId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getAlternativeListUsers = async altListId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/GetAlternativeListUsers/${altListId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternativeListUser = async (userId, altListId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteAlternativeListUser/${altListId}/${userId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createAlternativeListUser = async (userId, altListId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateAlternativeListUser/${altListId}/${userId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativeList = async (userId, altList) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/UpdateAlternativeList/${userId}`,
    postRequestOptions(altList)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const copyAlternative = async payload => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CopyAlternative_2`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const moveAlternative = async payload => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/MoveAlternative`,
    postRequestOptions(payload)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteAlternative = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteAlternative/${alternativeId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createLookback = async (userId, lookback) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/CreateLookback/${userId}`,
    postRequestOptions(lookback)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteLookbacksAlternative = async (userId, altId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/DeleteLookbacks_Alternative/${userId}/${altId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createSmartsheet = async altId => {
  return await fetch(
    `${mainApiUrl}/api/Smartsheets/CreateSheet/${altId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateSmartsheet = async altId => {
  return await fetch(
    `${mainApiUrl}/api/Smartsheets/UpdateFromSheet/${altId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createJiraProject = async altId => {
  return await fetch(
    `${mainApiUrl}/api/jira/createProject/${altId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getObjModels = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetObjModels/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFinModels = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetFinModels/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFinModel = async finModelId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetFinModel/${finModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createFinModel = async (userId, finModel) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateFinModel/${userId}`,
    postRequestOptions(finModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateFinModel = async (userId, finModel) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateFinModel/${userId}`,
    postRequestOptions(finModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteFinModel = async (userId, finModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteFinModel/${userId}/${finModelId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getObjModel = async objModelId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetObjModel/${objModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createObjModel = async (userId, objModel) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateObjModel/${userId}`,
    postRequestOptions(objModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateObjModel = async (userId, objModel) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateObjModel/${userId}`,
    postRequestOptions(objModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteObjModel = async (userId, objModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteObjModel/${userId}/${objModelId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModelResourceGroups = async optModelId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModelResourceGroups/${optModelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateOptModelResourceGroup = async (userId, resourceGroup) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/UpdateOptModelResourceGroup/${userId}`,
    postRequestOptions(resourceGroup)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getOptModels = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/GetOptModels/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createOptModel = async (userId, optModel) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/CreateOptModel/${userId}`,
    postRequestOptions(optModel)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteOptModel = async (userId, optModelId) => {
  return await fetch(
    `${mainApiUrl}/api/Optimization/DeleteOptModel/${userId}/${optModelId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const setHelpStatus = async (userId, viewName, showHide) => {
  return await fetch(
    `${mainApiUrl}/api/General/SetHelpStatus/${userId}/${viewName}/${showHide}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getHelpItem = async (helpItemId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/General/GetHelpItem/${helpItemId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const sendParticipantInvite = async (mode, participant) => {
  return await fetch(
    `${mainApiUrl}/api/Email/SendParticipantInvite/${mode}`,
    postRequestOptions(participant)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const sendParticipantLinks = async (scenarioId, email) => {
  return await fetch(
    `${mainApiUrl}/api/Email/SendParticipantLinks/${scenarioId}`,
    postRequestOptions({ email: email })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getResourceGroupUsersScenario = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Resource/GetResourceGroupUsers_Scenario/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const check2fa = async (userId, code) => {
  return await fetch(
    `${mainApiUrl}/api/User/Check2FA/${userId}/${code}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const send2fa = async (userId, modeid) => {
  return await fetch(
    `${mainApiUrl}/api/Email/Send2FA/${userId}/${modeid}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const setCurrentGroupPwcScoreId = async (scenarioId, pwcScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/SetCurrentGroupPWCScoreID/${scenarioId}/${pwcScoreId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const performCriteriaPrioritizationAI = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Objective/PerformCriteriaPrioritizationAI/${userId}/${scenarioId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const performAlternativePrioritizationAI = async (userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/ProcessAlternativePrioritizationAI/${userId}/${scenarioId}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const setCurrentGroupAltScoreId = async (scenarioId, altScoreId, levelId) => {
  return await fetch(
    `${mainApiUrl}/api/Alternative/SetCurrentGroupAltScoreID/${scenarioId}/${altScoreId}/${levelId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const uploadTasks = async (alternativeId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(`${mainApiUrl}/api/Task/UploadTasks/${alternativeId}`, {
    method: "POST",
    headers: {
      Authorization: getToken(),
    },
    body: data,
  })
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const uploadUsers = async (userId, enterpriseId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(
    `${mainApiUrl}/api/User/UploadUsers/${userId}/${enterpriseId}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
      },
      body: data,
    }
  )
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}
const uploadParticipants = async (userId, scenarioId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(
    `${mainApiUrl}/api/Scenario/UploadParticipants/${userId}/${scenarioId}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
      },
      body: data,
    }
  )
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}
const uploadAlternatives = async (userId, scenarioId, file) => {
  var data = new FormData()
  data.append("file", file)
  return await fetch(
    `${mainApiUrl}/api/Alternative/UploadAlternatives/${userId}/${scenarioId}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
      },
      body: data,
    }
  )
    .then(handleResponse)
    .then(r => {
      return r
    }, handleError)
}

const forgotPassword = async userId => {
  return await fetch(
    `${mainApiUrl}/api/User/ForgotPassword/${userId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createSecondaryScenario = async (
  userId,
  scenarioId,
  copyTeam,
  attributeName,
  scenarioChildType
) => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateSecondaryScenario/${userId}/${scenarioId}/${copyTeam}/${attributeName}/${scenarioChildType}`,
    postRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getPayments = async alternativeId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/GetPayments/${alternativeId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBusinessRuleTriggerOptions = async scope => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetBusinessRuleTriggerOptions/${scope}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBusinessRuleActionFields = async scope => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetBusinessRuleActionFields/${scope}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getBusinessRules = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/GetBusinessRules/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createBusinessRule = async businessRule => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateBusinessRule`,
    postRequestOptions(businessRule)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateBusinessRule = async businessRule => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateBusinessRule`,
    postRequestOptions(businessRule)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteBusinessRule = async businessRuleId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteBusinessRule/${businessRuleId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const processBusinessRulesForScenario = async scenarioId => {
  return await fetch(
    `${mainApiUrl}/api/General/ProcessBusinessRules_ForceAllScenario/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createBusinessRuleAction = async businessRuleAction => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/CreateBusinessRuleAction`,
    postRequestOptions(businessRuleAction)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateBusinessRuleAction = async businessRuleAction => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/UpdateBusinessRuleAction`,
    postRequestOptions(businessRuleAction)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteBusinessRuleAction = async businessRuleActionId => {
  return await fetch(
    `${mainApiUrl}/api/Scenario/DeleteBusinessRuleAction/${businessRuleActionId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createPayments = async (userId, payment) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/CreatePayment/${userId}`,
    postRequestOptions(payment)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updatePayments = async (userId, payment) => {
  return await fetch(
    `${mainApiUrl}/api/Contract/UpdatePayment/${userId}`,
    postRequestOptions(payment)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deletePayments = async paymentId => {
  return await fetch(
    `${mainApiUrl}/api/Contract/DeletePayment/${paymentId}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFormTypes = async () => {
  return await fetch(`${mainApiUrl}/api/Form/GetFormTypes`, getRequestOptions())
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFormFieldsScenario = async (enterpriseId, userId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetFormFields_Scenario/${enterpriseId}/${userId}/${scenarioId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateAlternativesForFormField = async (alternativeIds, formField) => {
  return await fetch(
    `${mainApiUrl}/api/Form/UpdateAlternatives_ForFormField`,
    postRequestOptions({ alternativeIds, formField })
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFormFieldSelectLists = async () => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetFormFieldSelectLists`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getForm = async formId => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetForm/${formId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFormWithValues = async (formId, itemId, scenarioId) => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetFormWithValues/${formId}/${itemId ?? 0}/${
      scenarioId ?? 0
    }`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getForms = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetForms/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFieldsByEnterpriseScope = async (enterpriseId, scope) => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetFieldsByEnterpriseScope/${enterpriseId}/${scope}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getFormFieldTypes = async () => {
  return await fetch(
    `${mainApiUrl}/api/Form/GetFormFieldTypes`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createForm = async form => {
  return await fetch(
    `${mainApiUrl}/api/Form/CreateForm`,
    postRequestOptions(form)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateForm = async (form, userID) => {
  return await fetch(
    `${mainApiUrl}/api/Form/UpdateForm/${form.formId}/${userID}`,
    postRequestOptions(form)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateFormWithValues = async (itemId, form) => {
  return await fetch(
    `${mainApiUrl}/api/Form/UpdateFormWithValues/${form.formId}/${itemId}`,
    postRequestOptions(form)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createField = async field => {
  return await fetch(
    `${mainApiUrl}/api/Form/CreateField`,
    postRequestOptions(field)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getReportDataSources = async enterpriseId => {
  return await fetch(
    `${mainApiUrl}/api/ReportData/GetReportDataSources/${enterpriseId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getReportViews = async () => {
  return await fetch(
    `${mainApiUrl}/api/ReportData/GetReportViews`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const getReportViewColumns = async (reportViewId) => {
  return await fetch(
    `${mainApiUrl}/api/ReportData/GetReportViewsColumns/${reportViewId}`,
    getRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const createReportDataSource = async reportDataSource => {
  return await fetch(
    `${mainApiUrl}/api/ReportData/CreateReportDataSource`,
    postRequestOptions(reportDataSource)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const updateReportDataSource = async reportDataSource => {
  return await fetch(
    `${mainApiUrl}/api/ReportData/UpdateReportDataSource`,
    postRequestOptions(reportDataSource)
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const deleteReportDataSource = async id => {
  return await fetch(
    `${mainApiUrl}/api/ReportData/DeleteReportDataSource/${id}`,
    deleteRequestOptions()
  )
    .then(handleResponse)
    .then(data => {
      return data
    }, handleError)
}

const api = {
  getReportDataSources,
  getReportViews,
  getReportViewColumns,
  createReportDataSource,
  updateReportDataSource,
  deleteReportDataSource,
  getForms,
  getFormWithValues,
  updateFormWithValues,
  getFormFieldSelectLists,
  getFormFieldsScenario,
  updateAlternativesForFormField,
  createField,
  createForm,
  updateForm,
  getPayments,
  createPayments,
  updatePayments,
  deletePayments,
  forgotPassword,
  uploadUsers,
  uploadTasks,
  getContractComparePayment,
  uploadAlternatives,
  uploadParticipants,
  setCurrentGroupPwcScoreId,
  performCriteriaPrioritizationAI,
  performAlternativePrioritizationAI,
  setCurrentGroupAltScoreId,
  check2fa,
  send2fa,
  sendParticipantInvite,
  getHelpItem,
  getBusinessRuleTriggerOptions,
  getBusinessRuleActionFields,
  setHelpStatus,
  getOptModels,
  deleteOptModel,
  createOptModel,
  createFinModel,
  updateFinModel,
  deleteFinModel,
  createObjModel,
  updateObjModel,
  deleteObjModel,
  getObjModel,
  createJiraProject,
  createSmartsheet,
  updateSmartsheet,
  getAlternativesLocations,
  createLookback,
  getEnterpriseLabel,
  checkTaskOwner,
  getTaskLog,
  deleteLookbacksAlternative,
  deleteAlternative,
  copyAlternative,
  moveAlternative,
  updateAlternativeList,
  lockAlternative,
  createAlternativeListUser,
  deleteAlternativeListUser,
  getAlternativeListUsers,
  getAlternativesByList,
  createAlternativeListAlternative,
  deleteAlternativeListAlternative,
  createAlternativeList,
  getAlternativeLists,
  checkUserLinkAccess,
  copyScenario,
  getOtherPortfolioUsers,
  createNotificationRule,
  getFinModel,
  updateNotificationRule,
  deleteNotificationRule,
  getNotificationTriggers,
  getNotificationRuleFields,
  getNotificationRules,
  getNmStatuses,
  getNmhedFteData,
  getEntitiesByParent,
  createNmhed,
  getNmhed,
  addUpdateScorecardItem,
  getAlternativeScorecardItems,
  getScorecardItemsByScorecard,
  getEntityDocs,
  createEntity,
  addUpdateEntityFieldData,
  updateEntity,
  getEntityFields,
  getEntityFieldData,
  getEntities,
  getCalcFields,
  runReport,
  getAlternativeWordDocument,
  getPowerPointDocument,
  runTelerikUserReport,
  getReport,
  getTelerikUserReport,
  exportViewReportByAlternatives,
  exportViewReport,
  createBcReportSet,
  updateBcReportSet,
  deleteBcReportSet,
  getResourceClassesByGroup,
  getResourceEstimateLog,
  createResourceGroupClass,
  updateResourceGroupClasses,
  updateResourceGroupTShirts,
  deleteResourceGroupClass,
  copyBcReportSet,
  getBcFields,
  getBcReportSets,
  getBcReportSets_CanView,
  getBusinessCase,
  getBcReportSet,
  getAlternativeByBusinessCaseId,
  createDashboard,
  updateDashboard,
  copyDashboard,
  deleteDashboard,
  getUserChartsForUser,
  getDashboardItemsForUser,
  getGeneralChart,
  getDashboard,
  getDashboards,
  createUserChart,
  copyUserChart,
  updateUserChart,
  deleteUserChart,
  getInfoSections,
  getInfoSection,
  createInfoSection,
  updateInfoSection,
  deleteInfoSection,
  getPageItem,
  getMenuItems,
  getActionItems,
  updateNmhed,
  getUsersByUserId,
  verifyCode,
  getUsersByUserNotifications,
  getUser,
  getUsersByEnterprise,
  getUserScenarioListString,
  getUserByEmail,
  getWorkflowsForEnterprise,
  getTaskTypesForEnterprise,
  createWorkflowTasksScenario,
  getList,
  getFieldHelp,
  getCostPoolTemplates,
  createCostPool,
  deleteCostPool,
  createCostPoolTemplate,
  updateCostPoolTemplate,
  getHolidays,
  createRoadmapAlternative,
  updateRoadmapAlternative,
  deleteRoadmapAlternative,
  getObjectives,
  getScenarioInputRatings,
  getRatingScales,
  getParticipantsByScenarioId,
  getEnterprises,
  processEnterpriseFiles,
  getEnterpriseList,
  createParticipant,
  updateParticipant,
  deleteParticipant,
  createUser,
  updateUser,
  deleteUser,
  createScenarioListItem,
  createObjective,
  updateObjective,
  createMeasure,
  updateMeasure,
  getPwcScore,
  getPwcUserScores,
  getPwcUserScore,
  getNextPwcScore,
  getPrevPwcScore,
  getScenario,
  getScenariosByUser,
  getNotificationEmails,
  updateNotificationEmail,
  sendInAppNotification,
  getAlternativeForecastSummary,
  getObjective,
  updatePwcUserScore,
  deletePwcUserScore,
  recalculateObjective,
  updateScenarioListItem,
  deleteScenarioListItem,
  getMeasures,
  updateScenario,
  setPreferenceWeightsToAHP,
  getAltScore,
  getAltUserScores,
  getAltUserScore,
  getWidget,
  updateConfigItem,
  deleteEntityDocument,
  getAlternativesByParams,
  getAlternativesByUserParams,
  getPwcResultsUsers,
  getPwcScoresForUsers,
  getAlternativeRatingsUsers,
  getAIPrompt,
  createAiSavedPrompt,
  deleteAiSavedPrompt,
  getAiSavedPrompts,
  getNextAltScore,
  getAltScores,
  getAlternativeLookbacks,
  applyAlternativeCostPools,
  getAlternativeLookback,
  createAlternativeLookback,
  getYears,
  getGPTSummary,
  askOpenAiAssistant,
  createOpenAiAssistant,
  updateAlternativeLookback,
  deleteAlternativeLookback,
  getPrevAltScore,
  deleteAltUserScore,
  updateCostPool,
  updateAltUserScore,
  recalculateAlternative,
  getAlternatives,
  getAlternativesByUser,
  getIdeasByUser,
  getAlternative,
  getAltScoreByAltId,
  getAlternativeRatings,
  getPwcConsensus,
  getPwcScores,
  getAltScoreConsensus,
  getEnterprise,
  getEnterpriseByCode,
  getNextAlternativeId,
  getPrevAlternativeId,
  resetObjectiveScores,
  getResourceGroupResults,
  getAlternativeTabs,
  getAlternativeListFilters,
  updateAlternativeListFilter,
  resetAlternativeScoreSingle,
  resetAlternativeScores,
  getParticipantByEmail,
  deleteObjective,
  deleteMeasure,
  createScenario,
  deleteScenario,
  updateAlternative,
  getBcTabOptions,
  scenarioApplyForecastTemplate,
  alternativeApplyForecastTemplate,
  getScenarioList,
  getPlans,
  getPlan,
  createPlan,
  updatePlan,
  deletePlan,
  copyPlan,
  createEnterpriseListItem,
  updateEnterpriseListItem,
  deleteEnterpriseListItem,
  createEnterprise,
  updateEnterprise,
  getCostPools,
  getEntitiesByEnterprise,
  getUserEntities,
  saveUserEntities,
  uploadEnterpriseLogo,
  getCostVariableSummary,
  getConfigItems,
  getBCCostPools,
  updateBCCostPool,
  updateAlternativeMetrics,
  clearAlternativeForecast,
  getBCSummary,
  getAlternativeChart,
  getFieldConfigItems,
  updateFieldConfigItem,
  createContractItem,
  updateContractItem,
  createContract,
  updateContract,
  deleteContractItem,
  deleteContract,
  getContractEntities,
  getRemainingContractAmounts,
  getOptResults,
  getResourceGroupsByBcCostPool,
  getContractCompare,
  getContractForecast,
  getContractsCostPools,
  getContractsForResource,
  getContractsForEnterprise,
  getResourceGroupTShirts,
  getContractItems,
  getLocation,
  sendSms,
  getAlternativeLocations,
  getFinancingSummary,
  createAlternativeLocation,
  updateAlternativeLocation,
  deleteAlternativeLocation,
  getUserAlternativeAccessData,
  updateUserAlternativeAccessData,
  getTask,
  getTaskList,
  getTasks,
  getKeyTasks,
  getTasksUserHasRole,
  getTaskTypeFieldOptions,
  getTaskFields,
  getGeneralTasks,
  getObjModels,
  createTask,
  updateTask,
  deleteTask,
  getTaskType,
  getTaskTypes,
  getScenarioTaskTypes,
  updateScenarioTaskTypes,
  getNoteReviewSummary,
  getNotes,
  createNote,
  updateNote,
  deleteNote,
  getDocuments,
  getGptDocuments,
  uploadGptDocument,
  deleteGptDocument,
  createDocument,
  deleteDocument,
  createAlternative,
  updateDocument,
  getOptModel,
  getOptModelAlternatives,
  updateOptModelAlternative,
  getDependencies,
  createDependency,
  updateDependency,
  deleteDependency,
  getOptModelGroups,
  createOptModelGroup,
  updateOptModelGroup,
  deleteOptModelGroup,
  getOptModelGroupAlternativeIds,
  updateOptModelGroupAlternativeIds,
  getOptModelObjectives,
  updateOptModelObjective,
  getCostVariables,
  getPotentialCostVarialbes,
  getScenarioCostPools,
  createCostVariable,
  deleteCostVariables,
  updateOptModel,
  runSingleOptimization,
  runMultiOptimization,
  getMixChart,
  updateResourceGroupResult,
  updateAlternativeForecastDates,
  getParetoGridData,
  getParetoItems,
  getParetoChart,
  getMixChartMulti,
  getMixChartPlan,
  getCostPoolSummary,
  getParetoGridDataSummary,
  getResourceGroups,
  getResourceGroupsByUser,
  getResourceGroupsByTShirtBundleId,
  deleteResourceGroupTShirts,
  getNextTShirtBundleId,
  getResourceGroup,
  getCompareItems,
  getAlternativeBaselines,
  createResourceGroup,
  deleteResourceGroup,
  getResourceGroupRates,
  createResourceGroupRate,
  getParticipant,
  deleteResourceGroupRate,
  getResourceGroupContracts,
  createResourceGroupContract,
  deleteResourceGroupContract,
  getResourceGroupUsers,
  getUserInfoByEmail,
  createResourceGroupUser,
  deleteResourceGroupUser,
  getResourceGroupCapacities,
  getFinModels,
  updateResourceGroupCapacity,
  getResourceGroupCostPools,
  getResourceGroupCostPool,
  createResourceGroupCostPool,
  deleteResourceGroupCostPool,
  getContractsForScenario,
  getResourceGroupCapacityData,
  getEnterpriseResourceChartClass,
  getEnterpriseResourceChartResource,
  getEnterpriseResourceChartScenario,
  getScenarioContractEntities,
  getAlternativeResourceGroups,
  getAlternativeResourcePlans,
  updateAlternativeResourcePlan,
  updateAlternativeResourceGroup,
  generateAlternativeResourcePlans,
  getAlternativeResourceBudgets,
  getAlternativeResourceBudgetsGrid,
  getAlternativeResourceBudgetsGridBundle,
  updateAlternativeResourceBudget,
  updateAlternativeResourceFromBudget,
  getAlternativeResourceBudgetsView,
  getResourceHeatmap,
  getResourceDrilldown,
  createEntityDocument,
  uploadTaskDocument,
  getResourceAreaAltChart,
  getResourceAreaRgChart,
  getResourceColumnChart,
  getAlternativeResourceStatus,
  getWidgetsByView,
  getAlternativesForCharts,
  getAlternativesBarChart,
  getAlternativesFinancialChart,
  getUserChartDrilldown,
  getObjRadarChart,
  getAltH2hChart,
  getAltScatterChart,
  getRoadmapChart,
  getAltScoresSecondary,
  getAlternativePhasesChart,
  getAlternativePhasesCostChart,
  getAltOptModels,
  getAltObjModels,
  getAltFinModels,
  getAltAllModels,
  getAlternativesBubbleChart,
  getAlternativesDecision,
  getAlternativeSensitivity,
  resetObjectiveSensitivity,
  sendAltResourceBudgetEmail,
  getUserCharts,
  getUserChart,
  getAlternativeListChart,
  calculateUserPercentages,
  downloadBusinessCaseReport,
  getBcReportOptions,
  getBcReportScenarioOptions,
  getBusinessCaseReport,
  getPwcScoreGroup,
  getAltScoreGroup,
  deletePwcUserScoresScenario,
  deleteUserAltScores,
  getResourceGroupUsersScenario,
  sendParticipantLinks,
  getDashboardScenario,
  clearResourceEstimates,
  getAlternativesSearch,
  getAlternativesUserHasRole,
  getUploaderFileLink,
  getResourceFlow,
  copyBudgetToForecast,
  getResourceFlowContracts,
  getPrograms,
  createPrograms,
  updatePrograms,
  deleteProgram,
  getFunds,
  createFund,
  updateFund,
  deleteFund,
  getOptModelResourceGroups,
  updateOptModelResourceGroup,
  createSecondaryScenario,
  createCalcField,
  updateCalcField,
  deleteCalcField,
  deleteAlternativeList,
  createRoadmap,
  updateRoadmap,
  deleteRoadmap,
  getRoadmaps,
  getRoadmap,
  createAlternativeRevision,
  acceptAlternativeRevision,
  removeAlternativeRevision,
  generateBusinessCaseReportBackup,
  getUserAccessAlternatives,
  updateUserPhotoBase64,
  getUserPhotoBase64,
  saveUserAccessAlternatives,
  getAlternativesForAccess,
  updateAlternativeFlowThrough,
  getLog,
  getAlternativePhases,
  createAlternativePhase,
  updateAlternativePhase,
  deleteAlternativePhase,
  createBusinessRule,
  updateBusinessRule,
  deleteBusinessRule,
  processBusinessRulesForScenario,
  getBusinessRules,
  createBusinessRuleAction,
  updateBusinessRuleAction,
  deleteBusinessRuleAction,
  shareTelerikUserReport,
  getTelerikUserReportAssociatedUserIds,
  getTelerikUserReports,
  getFormTypes,
  getForm,
  getFieldsByEnterpriseScope,
  getFormFieldTypes,
  createTaskType,
  updateTaskType,
  deleteTaskType,
  createTaskTypeField,
  updateTaskTypeField,
  deleteTaskTypeField,
  getTaskTypeGeneralListItems,
  getTaskTypeListItems,
  createTaskTypeListItem,
  updateTaskTypeListItem,
  deleteTaskTypeListItem,
  getTaskTypeFields,
  createWorkflow,
  getTaskListWorkflows,
  getWorkflow,
  updateWorkflow,
  getWorkflowTaskTypes,
  createWorkflowTaskType,
  updateWorkflowTaskType,
  deleteWorkflowTaskType,
  getWorkflowActions,
  createWorkflowAction,
  updateWorkflowAction,
  deleteWorkflowAction,
  getScenarioTabSections,
  getScenariosRollup,
  createOrUpdateScenarioTabSection,
  deleteScenarioTabSection,
  addUpdatePlanAlternative,
  getAlternativesPlan,
  getScenarioGroups,
  createScenarioGroup,
  updateScenarioGroup,
  deleteScenarioGroup,
  deleteAlternativeTaskList,
  createAlternativeTaskList,
  getEnterpriseBcRateYears,
  getEnterpriseBcRates,
  createEnterpriseBcRate,
  updateEnterpriseBcRate,
  deleteEnterpriseBcRate,
  addOrUpdateEnterpriseBcRateYear
}

export default api
