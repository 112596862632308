import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useFetcher from "hooks/useFetcher"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const RollupModal = ({ isOpen, toggleModal, setRollupAlternative }) => {
  const currentUser = userService.getLoggedInUser()
  const [selectedScenarioId, setSelectedScenarioId] = useState(-1)
  const [selectedAlternativeId, setSelectedAlternativeId] = useState(-1)
  const [scenarios, loadingScenarios, loadScenarios] = useFetcher(
    () => api.getScenariosByUser(currentUser.userID, 0),
    [],
    true
  )
  const [alternatives, loadingAlternatives, loadAlternatives, setAlternatives] =
    useFetcher(
      scenarioId => api.getAlternatives(currentUser.userID, scenarioId),
      [],
      true
    )

  useEffect(() => {
    if (isOpen) {
      setSelectedScenarioId(-1)
      setSelectedAlternativeId(-1)
      loadScenarios()
    }
  }, [isOpen])

  const save = async () => {
    let alt = alternatives.find(x => x.alternativeID == selectedAlternativeId)
    if (alt != undefined) {
      setRollupAlternative(alt)
      toggleModal()
    }
  }

  const clear = () => {
    setRollupAlternative({ alternativeID: 0, alternativeName: "" })
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Set Rollup
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Scenario</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedScenarioId}
            disabled={loadingScenarios}
            onChange={e => {
              setSelectedScenarioId(e.target.value)
              setSelectedAlternativeId(-1)
              if (e.target.value != -1) {
                loadAlternatives(e.target.value)
              } else {
                setAlternatives([])
              }
            }}
          >
            <option value={-1}>Select</option>
            {scenarios
              .sort((a, b) => {
                let prop = "scenarioName"
                if (a[prop] < b[prop]) {
                  return -1
                }
                if (a[prop] > b[prop]) {
                  return 1
                }
                return 0
              })
              .map((s, idx) => (
                <option key={idx} value={s.scenarioID}>
                  {s.scenarioName}
                </option>
              ))}
          </select>
        </div>
        {selectedScenarioId != -1 && (
          <div className="mb-3">
            <Label className="form-label">Alternative</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedAlternativeId}
              onChange={e => {
                setSelectedAlternativeId(e.target.value)
              }}
              disabled={loadingAlternatives}
            >
              <option value={-1}>Select</option>
              {alternatives
                .sort((a, b) => {
                  let prop = "alternativeName"
                  if (a[prop] < b[prop]) {
                    return -1
                  }
                  if (a[prop] > b[prop]) {
                    return 1
                  }
                  return 0
                })
                .map((s, idx) => (
                  <option key={idx} value={s.alternativeID}>
                    {s.alternativeName}
                  </option>
                ))}
            </select>
          </div>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <>
          <button type="button" className="btn btn-danger" onClick={clear}>
            Clear
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
            disabled={selectedAlternativeId == -1}
          >
            Save
          </button>
        </>
      </div>
    </Modal>
  )
}

export default RollupModal
