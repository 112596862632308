import { Tooltip } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Col, Input, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import OptimizationAlert from "./Components/optimizationAlert"
import CustomTable from "components/custom/customTable"

const ProjectConstraints = ({
  activeTab,
  scenario,
  triggerSave,
  setTriggerSave,
  planningCategoryLabel
}) => {
  const currentUser = userService.getLoggedInUser()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [optModel, setOptModel] = useState(null)
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (activeTab == "ProjectConstraints" && scenario != null) {
      loadData(true)
    }
  }, [activeTab, scenario])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async showLoading => {
    try {
      if (showLoading) setIsLoading(true)
      let om = await api.getOptModel(
        optObjFin != null && optObjFin.opt != null
          ? optObjFin.opt.optModelID
          : scenario.currentOptModelID
      )
      setOptModel(om)
      let optModels = await api.getOptModelAlternatives(
        scenario.scenarioID,
        om?.optModelID ?? 0,
        om?.objModelID ?? 0,
        om?.finModelID ?? 0
      )
      setData(optModels)

      setColumns([
        {
          title: "ID",
          field: "alternativeNum",
          thStyle: { width: "50px" },
          sort: true,
        },
        {
          title: "Project",
          field: "alternativeName",
          sort: true,
          formatter: row => {
            return (
              <div className="one-line-elipsis">
                <Tooltip title={<h6>{row.alternativeName}</h6>}>
                  <span>
                    <b> {row.alternativeName}</b>
                  </span>
                </Tooltip>
              </div>
            )
          },
        },
        {
          title: planningCategoryLabel,
          field: "planningCategory",
          thStyle: { width: "20%" },
          sort: true,
          formatter: row => {
            return (
              <div className="one-line-elipsis">
                <Tooltip title={<h5>{row.planningCategory}</h5>}>
                  <div
                    style={{ marginLeft: "10px" }}
                    dangerouslySetInnerHTML={{
                      __html: row.planningCategory,
                    }}
                  ></div>
                </Tooltip>
              </div>
            )
          },
        },
        {
          title: "Benefit Score",
          field: "benefitScore",
          formatter: "stringAsHtml",
          sort: true,
          thStyle: { width: "15%" },
        },
        {
          title: "Group Rating",
          field: "groupRating",
          formatter: "stringAsHtml",
          sort: true,
          thStyle: { width: "10%" },
        },
        {
          title: "Cost",
          field: "cost",
          sort: true,
          thStyle: { width: "10%" },
          formatter: "currency",
        },
        {
          title: "Must",
          field: "mustHave",
          tdStyle: { textAlign: "center" },
          thStyle: { width: "7%" },
          sort: true,
          formatter: row => {
            return (
              <Input
                type="checkbox"
                checked={row.mustHave}
                onClick={() => changeProp("mustHave", row, optModels)}
              />
            )
          },
        },
        {
          title: "Must Not",
          field: "cantHave",
          tdStyle: { textAlign: "center" },
          thStyle: { width: "7%" },
          sort: true,
          formatter: row => {
            return (
              <Input
                type="checkbox"
                checked={row.cantHave}
                onClick={() => changeProp("cantHave", row, optModels)}
              />
            )
          },
        },
        {
          title: "Allow Partial",
          field: "partial",
          tdStyle: { textAlign: "center" },
          thStyle: { width: "7%" },
          sort: true,
          formatter: row => {
            return (
              <Input
                type="checkbox"
                checked={row.partial}
                onClick={() => changeProp("partial", row, optModels)}
              />
            )
          },
        },
      ])

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const save = async () => {
    setTriggerSave(false)
    try {
      let itemsToUpdate = data.filter(x => x.isDirty)
      if (itemsToUpdate.length > 0) {
        let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
          return await api.updateOptModelAlternative(currentUser.userID, x)
        })
        await Promise.all(itemsToUpdateTasks.map(t => t()))
        loadData()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const changeProp = (prop, alt, data) => {
    let copy = [...data]
    let item = copy.find(x => x.alternativeID == alt.alternativeID)
    item[prop] = !item[prop]

    if (prop == "mustHave") {
      if (item[prop]) {
        item["cantHave"] = false
      }
    }
    if (prop == "cantHave") {
      if (item["mustHave"]) {
        item["mustHave"] = false
      }
      if (item["partial"]) {
        item["partial"] = false
      }
    }

    item.isDirty = true
    setData(copy)

    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            document.getElementById("saveOptimizations").click()
          }}
        >
          <i className="fas fa-save"></i> Save
        </button>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  return (
    <React.Fragment>
      <Row>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
      </Row>
      <OptimizationAlert optModel={optModel} />
      {!isLoading && data.length > 0 && columns.length > 0 && (
        <Row>
          <Col>
            <CustomTable items={data} setItems={setData} columns={columns} />
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default ProjectConstraints
