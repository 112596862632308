import { useEffect, useState } from "react"

const useFetcher = (apiCall, defaultValue = [], delayedExecution = false) => {
  const [data, setData] = useState(defaultValue)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!delayedExecution && apiCall != undefined) {
      execute()
    }
  }, [delayedExecution])

  const execute = async (param) => {
    setIsLoading(true)
    let result = await apiCall(param)
    setData(result)
    setIsLoading(false)
  }

  return [data, isLoading, execute, setData]
}

export default useFetcher
