import useModal from "hooks/useModalHook"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditCostPoolModal from "./Modals/EditCostPoolModal"
import isNullOrEmpty from "utils/isNullOrEmpty"
import EditCostPoolTemplateModal from "./Modals/EditCostPoolTemplateModal"
import { swalWithConfirmButton } from "components/custom/swal"
import { toast } from "react-toastify"

const CostPoolsSection = ({ enterpriseId, activeTab }) => {
  const currentUser = userService.getLoggedInUser()
  const [costPools, setCostPools] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [inflationRates, setInflationRates] = useState([])
  const [costPoolTemplates, setCostPoolTemplates] = useState([])
  const [selectedCostPoolTemplate, setSelectedCostPoolTemplate] = useState(null)
  const [templateToEdit, setTemplateToEdit] = useState(null)

  const [costPoolToEdit, setCostPoolToEdit] = useState(null)
  const { show: showEditCostPoolModal, toggle: toggleShowEditCostPoolModal } =
    useModal()
  const { show: showEditTemplateModal, toggle: toggleEditTemplateModal } =
    useModal()

  useEffect(() => {
    if (enterpriseId > -1 && activeTab == "7") {
      loadData()
    }
  }, [enterpriseId, activeTab])

  useEffect(() => {
    if (
      !isNullOrEmpty(selectedCostPoolTemplate) &&
      selectedCostPoolTemplate != -1
    ) {
      loadItems()
    }
  }, [selectedCostPoolTemplate])

  const loadData = async (costPoolTemplate = undefined) => {
    try {
      let list = await api.getCostPoolTemplates(enterpriseId)
      setCostPoolTemplates(list)
      setSelectedCostPoolTemplate(
        costPoolTemplate == undefined
          ? list.length > 0
            ? list[0]
            : null
          : costPoolTemplate
      )

      let bcRates = await api.getEnterpriseBcRates(enterpriseId)
      setInflationRates(bcRates.filter(x => x.rateType == "Inflation"))
    } catch (err) {}
  }

  const loadItems = async () => {
    try {
      setIsLoading(true)
      let list = await api.getCostPools(
        enterpriseId,
        selectedCostPoolTemplate.costPoolTemplateID
      )
      setCostPools(list)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const updateCostPoolUseCost = async costPool => {
    await api.updateCostPool(
      currentUser.userID,
      costPool.costPoolID == 0 ? false : true,
      {
        ...costPool,
        costPoolID:
          costPool.costPoolID == Number.MAX_SAFE_INTEGER
            ? 0
            : costPool.costPoolID,
      }
    )
  }

  const updateCostPoolFlowThrough = costPool => {}

  const updateListItem = async listItem => {
    try {
      await api.updateEnterpriseListItem(
        enterpriseId,
        "ResourceClass",
        listItem
      )
    } catch (err) {
      console.log(err)
    }
  }

  const addCostPool = () => {
    setCostPoolToEdit({
      costPoolID: -1,
      enterpriseID: enterpriseId,
      flowThrough: false,
      costPoolName: "",
      costPoolTypeID: -1,
      costPoolType: "Other Items",
      costPoolTemplateID:
        selectedCostPoolTemplate == null
          ? -1
          : selectedCostPoolTemplate.costPoolTemplateID,
      costPoolTemplate:
        selectedCostPoolTemplate == null
          ? -1
          : selectedCostPoolTemplate.costPoolTemplateName,
      inflationRateID: 0,
    })
    toggleShowEditCostPoolModal()
  }

  const addCostPoolTemplate = () => {
    setTemplateToEdit({
      costPoolTemplateID: -1,
      costPoolTemplateName: "",
      description: "",
      inactive: false,
      enterpriseID: enterpriseId,
    })
    toggleEditTemplateModal()
  }

  const editCostPoolTemplate = () => {
    setTemplateToEdit({
      ...selectedCostPoolTemplate,
      enterpriseID: enterpriseId,
    })
    toggleEditTemplateModal()
  }

  const deleteCostPool = costPoolId => {
    swalWithConfirmButton
      .fire({
        title: "Are you sure you want to delete this Cost Pool?",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      .then(r => {
        if (r) {
          api.deleteCostPool(currentUser.userID, costPoolId).then(resp => {
            toast.success("Cost Pool Deleted")
            loadData()
          })
        }
      })
  }

  return (
    <React.Fragment>
      {costPoolTemplates.length > 0 && (
        <Row>
          <Col sm="8">
            <div className="mb-3">
              <Label className="form-label">Template</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={
                  selectedCostPoolTemplate == null
                    ? -1
                    : selectedCostPoolTemplate.costPoolTemplateID
                }
                onChange={e => {
                  setSelectedCostPoolTemplate(
                    costPoolTemplates.find(
                      x => x.costPoolTemplateID == e.target.value
                    )
                  )
                }}
              >
                <option value={-1}>Select Cost Pool Template</option>
                {costPoolTemplates.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.costPoolTemplateID}`}>
                      {a.costPoolTemplateName}
                    </option>
                  )
                })}
              </select>
            </div>
          </Col>
          <Col md="4">
            <div className="d-flex-row ai-end w-100 h-100 gap-1">
              <button
                type="button"
                className="btn btn-primary mb-3"
                onClick={addCostPoolTemplate}
              >
                Add Template
              </button>

              {!isNullOrEmpty(selectedCostPoolTemplate) && (
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={editCostPoolTemplate}
                >
                  Edit Template
                </button>
              )}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm="10">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isNullOrEmpty(selectedCostPoolTemplate) &&
            selectedCostPoolTemplate.enterpriseID != 0 && (
              <button
                type="button"
                className="btn btn-primary mb-3"
                onClick={addCostPool}
              >
                Add Cost Pool
              </button>
            )}
          {!isLoading &&
            selectedCostPoolTemplate != null &&
            selectedCostPoolTemplate != -1 &&
            costPools.length > 0 && (
              <>
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th className="col-2">Section</th>
                      <th className="col-3">Cost Pool</th>
                      <th className="col-1">Use Cost Pool</th>
                      <th className="col-2">Inflation Rate</th>
                      <th className="col-1">Resource Flow Through</th>
                      <th className="col-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {costPools.map((x, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{x.costPoolType}</td>
                          <td>{x.costPoolName}</td>
                          <td>
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id={`chb1-${idx}`}
                              checked={x.costPoolID > 0}
                              value={x.costPoolID > 0}
                              onClick={e => {
                                let costPoolsCopy = [...costPools]
                                let toUpdate = costPoolsCopy.find(
                                  c => c.bcLineItemID == x.bcLineItemID
                                )
                                toUpdate.costPoolID =
                                  toUpdate.costPoolID > 0
                                    ? 0
                                    : Number.MAX_SAFE_INTEGER
                                toUpdate.flowThrough =
                                  toUpdate.costPoolID == 0
                                    ? false
                                    : toUpdate.flowThrough
                                toUpdate.enterpriseID = enterpriseId
                                updateCostPoolUseCost(toUpdate)
                                setCostPools(costPoolsCopy)
                              }}
                            />
                          </td>
                          <td>
                            <select
                              className="form-control form-select select2 mb-xxl-0"
                              value={x.inflationRateID}
                              onChange={e => {
                                let costPoolsCopy = [...costPools]
                                let toUpdate = costPoolsCopy.find(
                                  c => c.bcLineItemID == x.bcLineItemID
                                )
                                toUpdate.inflationRateID = e.target.value
                                toUpdate.enterpriseID = enterpriseId
                                updateCostPoolUseCost(toUpdate)
                                setCostPools(costPoolsCopy)
                              }}
                            >
                              <option value={0}>None</option>
                              {inflationRates.map((r, idx) => (
                                <option key={idx} value={r.bcRateID}>
                                  {r.bcRateName}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id={`chb2-${idx}`}
                              checked={x.flowThrough}
                              // value={x.flowThrough}
                              onClick={e => {
                                let costPoolsCopy = [...costPools]
                                let toUpdate = costPoolsCopy.find(
                                  c => c.bcLineItemID == x.bcLineItemID
                                )
                                toUpdate.flowThrough = !toUpdate.flowThrough
                                toUpdate.enterpriseID = enterpriseId
                                updateCostPoolUseCost(toUpdate)
                                setCostPools(costPoolsCopy)
                              }}
                              disabled={x.costPoolID == 0}
                            />
                          </td>
                          <td>
                            {!isNullOrEmpty(selectedCostPoolTemplate) &&
                              selectedCostPoolTemplate.enterpriseID != 0 &&
                              !x.isUsed && (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => deleteCostPool(x.costPoolID)}
                                >
                                  Delete
                                </button>
                              )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
        </Col>
      </Row>

      <EditCostPoolModal
        isOpen={showEditCostPoolModal}
        toggleModal={toggleShowEditCostPoolModal}
        costPoolToEdit={costPoolToEdit}
        setCostPoolToEdit={setCostPoolToEdit}
        inflationRates={inflationRates}
        reload={loadItems}
      />
      <EditCostPoolTemplateModal
        isOpen={showEditTemplateModal}
        toggleModal={toggleEditTemplateModal}
        reload={loadData}
        templateToEdit={templateToEdit}
        setTemplateToEdit={setTemplateToEdit}
      />
    </React.Fragment>
  )
}

export default CostPoolsSection
