import { TextareaAutosize, Tooltip } from "@material-ui/core"
import HelpIconWithModal from "components/custom/helpIconWithModal"
import {
  swalWith3Buttons,
  swalWithConfirmAndCancelButtons,
} from "components/custom/swal"
import useDidUpdateEffect from "hooks/useDidUpdateEffect"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { applyMiddleware } from "redux"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"

export const CostPoolEditableCell = ({
  costPoolYear,
  costPool,
  listName,
  propName,
  changeProp,
  prefix = "",
  suffix = "",
  isBoldFunc = undefined
}) => {
  const year = costPool?.bcCostPoolYears?.find(
    x => x.yearID == costPoolYear?.yearID
  )

  return (
    <React.Fragment>
      {year != undefined && (
        <CurrencyInput
          className="form-control align-end"
          style={{ padding: "1px", fontSize: "12px", fontWeight: isBoldFunc != undefined && isBoldFunc(year[propName]) ? 'bold' : 'normal' }}
          value={year[propName]}
          onValueChange={(value, name, values) => {
            changeProp(
              listName,
              year.yearID,
              values.float,
              costPool.bcCostPoolID
            )
          }}
          disabled={costPool.flowThrough == true}
          prefix={prefix}
          suffix={suffix}
        />
      )}
    </React.Fragment>
  )
}

export const getColorsByTableName = tableName => {
  if (tableName == "costTable1") {
    return { backgroundColor: "#006601", color: "white" }
  }
  if (tableName == "costTable2") {
    return { backgroundColor: "#339969", color: "white" }
  }
  if (tableName == "costTable3") {
    return { backgroundColor: "#cdfccf", color: "black" }
  }
  if (tableName == "benefitTable1") {
    return { backgroundColor: "#010362", color: "white" }
  }
  if (tableName == "benefitTable2") {
    return { backgroundColor: "#0098cd", color: "white" }
  }
  if (tableName == "benefitTable3") {
    return { backgroundColor: "#ceeaff", color: "black" }
  }
}

const ForecastSection = ({
  alternativeId,
  alternative,
  activeTab,
  triggerSave,
  setTriggerSave,
  changeAlternativeProp,
  scenario,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [forecastSummary, setForecastSummary] = useState([])
  const [costTable1, setCostTable1] = useState([])
  const [costTable2, setCostTable2] = useState([])
  const [costTable3, setCostTable3] = useState([])
  const [costTotals, setCostTotals] = useState(null)
  const [benefitTable1, setBenefitTable1] = useState([])
  const [benefitTable2, setBenefitTable2] = useState([])
  const [benefitTable3, setBenefitTable3] = useState([])
  const [benefitTotals, setBenefitTotals] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditNoteModalVisible, setIsEditNoteModalVisible] = useState(false)
  const [expenseOptions, setExpenseOptions] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const [showResourceEstimates, setShowResourceEstimates] = useState(false)
  const [resourceEstimateData, setResourceEstimateData] = useState([])

  const [useRates, setUseRates] = useState(false)

  useDidUpdateEffect(() => {
    loadData(true)
  }, [useRates])

  //[{ yearID}]
  const [costPoolYears, setCostPoolYears] = useState([])

  useEffect(() => {
    if (activeTab == "Forecast") {
      constructFiscalYears()
      loadData()
    }
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (triggerSave) {
      saveData()
    }
  }, [triggerSave])

  const toggleEditNoteModal = () =>
    setIsEditNoteModalVisible(!isEditNoteModalVisible)

  const constructFiscalYears = () => {
    let years = []
    let dt = new Date()
    let dtYearStart = dt.getFullYear() - 10
    for (let i = 0; i <= 25; i++) {
      years.push(dtYearStart + i)
    }
    setFiscalYears(years)
  }

  const loadData = async toggleLoading => {
    if (toggleLoading === undefined || toggleLoading === true)
      setIsLoading(true)
    let costs = await api.getBCCostPools(alternativeId, "cost", false, useRates)
    let costWithYears = costs.find(
      x => x.bcCostPoolYears !== null && x.bcCostPoolYears.length > 0
    )
    if (costWithYears !== undefined) {
      setCostPoolYears(
        costWithYears.bcCostPoolYears.map(y => {
          return { yearID: y.yearID }
        })
      )
    }
    let ct1 = []
    let ct2 = []
    let ct3 = []
    for (let i = 0; i < costs.length; i++) {
      if (i == 0) {
        ct1.push(costs[i])
        continue
      }
      if (ct1.filter(x => x.bcCostPoolID < 0).length !== 2) {
        ct1.push(costs[i])
        continue
      } else {
        if (ct2.filter(x => x.bcCostPoolID < 0).length !== 2) {
          ct2.push(costs[i])
          continue
        } else {
          if (ct3.filter(x => x.bcCostPoolID < 0).length !== 2) {
            ct3.push(costs[i])
            continue
          }
        }
      }
    }
    setCostTable1(ct1)
    setCostTable2(ct2)
    setCostTable3(ct3)
    setCostTotals(costs[costs.length - 1])

    let benefits = await api.getBCCostPools(
      alternativeId,
      "benefit",
      false,
      useRates
    )
    let bt1 = []
    let bt2 = []
    let bt3 = []
    for (let i = 0; i < benefits.length; i++) {
      if (i == 0) {
        bt1.push(benefits[i])
        continue
      }
      if (bt1.filter(x => x.bcCostPoolID < 0).length !== 2) {
        bt1.push(benefits[i])
        continue
      } else {
        if (bt2.filter(x => x.bcCostPoolID < 0).length !== 2) {
          bt2.push(benefits[i])
          continue
        } else {
          if (bt3.filter(x => x.bcCostPoolID < 0).length !== 2) {
            bt3.push(benefits[i])
            continue
          }
        }
      }
    }
    setBenefitTable1(bt1)
    setBenefitTable2(bt2)
    setBenefitTable3(bt3)
    setBenefitTotals(benefits[benefits.length - 1])

    await loadForecastSummary()
    await loadExpenseOptions()

    let estimates = await api.getResourceFlow(currentUser.userID, alternativeId)
    setResourceEstimateData(estimates)

    setIsLoading(false)
  }

  const loadForecastSummary = async () => {
    let summary = await api.getAlternativeForecastSummary(alternativeId)
    setForecastSummary(
      summary.map(x => {
        let ret = {}
        Object.keys(x)
          .filter(
            k =>
              k.startsWith("c-") ||
              k == "backColor" ||
              k == "format" ||
              k == "bold"
          )
          .forEach(p => {
            ret[p] = x[p]
          })
        return ret
      })
    )
  }

  const loadExpenseOptions = async () => {
    let exp = await api.getList(currentUser.userID, "ExpenseType")
    setExpenseOptions(exp)
  }

  const saveData = async () => {
    try {
      let rowsToUpdate = []
      rowsToUpdate = rowsToUpdate.concat(costTable1.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(costTable2.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(costTable3.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(benefitTable1.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(benefitTable2.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(benefitTable3.filter(x => x.isDirty))

      if (rowsToUpdate.length > 0) {
        let rowsToUpdateTasks = rowsToUpdate.map(x => async () => {
          return await api.updateBCCostPool(currentUser.userID, x)
        })
        await Promise.all(rowsToUpdateTasks.map(t => t()))
        await api.updateAlternativeMetrics(alternativeId)
        reload && reload()
        setTriggerSave(false)
        toast.success(`Forecast updated successfuly`)
      } else {
        setTriggerSave(false)
      }
      await loadData(false)
    } catch (err) {
      setTriggerSave(false)
    }
  }

  const clearForecast = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to clear the forecast for this project?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.clearAlternativeForecast(alternativeId)
          await loadData()
        }
      })
  }

  const changeProp = (tableNameToModify, yearID, value, bcCostPoolId) => {
    let list = [...getListByTableName(tableNameToModify)]
    let setter = getSetterByTableName(tableNameToModify)
    let itemToModify = list.find(x => x.bcCostPoolID == bcCostPoolId)
    let yearToModify = itemToModify.bcCostPoolYears.find(
      x => x.yearID == yearID
    )
    if (yearToModify !== undefined) yearToModify.amount = value
    itemToModify.isDirty = true
    itemToModify.totalAmount = itemToModify.bcCostPoolYears.reduce(
      (acc, item) => acc + item.amount,
      0
    )

    setter(list)

    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              document.getElementById("saveAlternative").click()
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }
  const changeCostPoolProp = (
    tableNameToModify,
    propToModify,
    value,
    bcCostPoolId
  ) => {
    let list = [...getListByTableName(tableNameToModify)]
    let setter = getSetterByTableName(tableNameToModify)
    let itemToModify = list.find(x => x.bcCostPoolID == bcCostPoolId)
    itemToModify[propToModify] = value
    itemToModify.isDirty = true

    setter(list)

    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              document.getElementById("saveAlternative").click()
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const getSetterByTableName = tableName => {
    if (tableName == "costTable1") {
      return setCostTable1
    }
    if (tableName == "costTable2") {
      return setCostTable2
    }
    if (tableName == "costTable3") {
      return setCostTable3
    }
    if (tableName == "benefitTable1") {
      return setBenefitTable1
    }
    if (tableName == "benefitTable2") {
      return setBenefitTable2
    }
    if (tableName == "benefitTable3") {
      return setBenefitTable3
    }
  }

  const getListByTableName = tableName => {
    if (tableName == "costTable1") {
      return costTable1
    }
    if (tableName == "costTable2") {
      return costTable2
    }
    if (tableName == "costTable3") {
      return costTable3
    }
    if (tableName == "benefitTable1") {
      return benefitTable1
    }
    if (tableName == "benefitTable2") {
      return benefitTable2
    }
    if (tableName == "benefitTable3") {
      return benefitTable3
    }
  }

  const zeroIfNotValid = value => {
    if (value == undefined || value == null || value == "") {
      return 0
    }

    return value
  }

  const getTotals = (table, propToSum) => {
    return table.reduce((acc, item) => {
      return acc + item[propToSum]
    }, 0)
  }

  const editNote = (note, list, listName) => {
    let copy = [...list]
    copy.forEach(element => {
      element.showTextarea = false
    })
    let item = copy.find(x => x.bcCostPoolID == note.bcCostPoolID)
    item.showTextarea = true
    let setter = getSetterByTableName(listName)
    setter(copy)
  }

  const getTableRowsToRender = (list, listName) => {
    return (
      <React.Fragment>
        {list.length > 0 &&
          list.map((c, i) => {
            if (i == 0 || c.bcCostPoolID > 0)
              return (
                <tr
                  key={i}
                  className={useRates ? "always-disabled" : ""}
                  style={{
                    backgroundColor:
                      i == 0
                        ? getColorsByTableName(listName).backgroundColor
                        : "inherit",
                    color:
                      i == 0 ? getColorsByTableName(listName).color : "inherit",
                  }}
                >
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: i == 0 ? 600 : "inherit",
                      backgroundColor:
                        getColorsByTableName(listName).backgroundColor,
                      color: getColorsByTableName(listName).color,
                    }}
                  >
                    {c.costPoolName}
                  </td>
                  {costPoolYears.length > 0 &&
                    costPoolYears.map((y, idx) => (
                      <td key={idx}>
                        {i !== 0 && (
                          <CostPoolEditableCell
                            costPool={c}
                            costPoolYear={y}
                            changeProp={changeProp}
                            listName={listName}
                            propName="amount"
                            prefix="$"
                            key={idx}
                          />
                        )}
                      </td>
                    ))}

                  <td
                    className="align-end"
                    style={{
                      backgroundColor: i !== 0 ? "lightgray" : "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {i !== 0 && currencyFormatter.format(c.totalAmount)}
                  </td>
                  {!showResourceEstimates && (
                    <td style={{ padding: "1px" }}>
                      {i == 0 ? (
                        c.notes
                      ) : c.showTextarea ? (
                        <TextareaAutosize
                          className="form-control"
                          onChange={e =>
                            changeCostPoolProp(
                              listName,
                              "notes",
                              e.target.value,
                              c.bcCostPoolID
                            )
                          }
                          value={c.notes}
                          disabled={c.flowThrough == true}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "600px",
                              marginBottom: "0px",
                            }}
                          >
                            {c.flowThrough == true
                              ? "This is a flow through cost pool. See the Resource Estimate tab. "
                              : ""}{" "}
                            {c.notes}
                          </p>
                          {c.flowThrough != true && (
                            <i
                              className="fas fa-edit"
                              style={{ alignSelf: "center", cursor: "pointer" }}
                              onClick={() => editNote(c, list, listName)}
                            ></i>
                          )}
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              )
          })}
        {list.length > 0 && (
          <tr
            style={{
              backgroundColor: getColorsByTableName(listName).backgroundColor,
              color: getColorsByTableName(listName).color,
              fontWeight: 600,
            }}
          >
            <td style={{ textAlign: "right" }}>
              {list[list.length - 1].costPoolName}
            </td>
            {costPoolYears.length > 0 &&
              costPoolYears.map((y, idx) => (
                <td key={idx} className="align-end">
                  {currencyFormatter.format(getYearTotals(list, y))}
                </td>
              ))}
            <td className="align-end" style={{ fontWeight: "bold" }}>
              {currencyFormatter.format(getTotals(list, "totalAmount"))}
            </td>
            {!showResourceEstimates && <td></td>}
          </tr>
        )}
      </React.Fragment>
    )
  }

  const getYearTotals = (table, year) => {
    return table.reduce((acc, item) => {
      const existingYear = item.bcCostPoolYears.find(
        x => x.yearID == year.yearID
      )
      return acc + (existingYear !== undefined ? existingYear.amount : 0)
    }, 0)
  }

  const copyBudgetToForecast = async costPoolId => {
    await api.copyBudgetToForecast(alternativeId, costPoolId)
    toast.success("Budget copied successfully")
    loadData()
  }

  const copyAllBudgetsToForecast = async () => {
    let tasks = resourceEstimateData.map(x => async () => {
      return await api.copyBudgetToForecast(alternativeId, x.costPoolID)
    })
    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
      toast.success("Budgets copied successfully")
      loadData()
    }
  }

  const changeNumberOfYears = async value => {
    if (value < alternative.numForecastYears) {
      let excludedYears = []
      for (
        let i = 1;
        i <= parseInt(parseInt(alternative.numForecastYears) - parseInt(value));
        i++
      ) {
        excludedYears.push(
          `FY ${costPoolYears[costPoolYears.length - i].yearID}`
        )
      }
      swalWithConfirmAndCancelButtons
        .fire({
          title: `You are decreasing the planning horizon from ${
            alternative.numForecastYears
          } to ${value} years. All forecast data for ${excludedYears.join(
            ", "
          )} will be deleted. Do you wish to continue?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then(async result => {
          if (result.isConfirmed) {
            changeAlternativeProp("numForecastYears", value, true)
            await api.updateAlternativeForecastDates(
              currentUser.userID,
              alternativeId,
              "keep",
              alternative.fyStart,
              value
            )
            await loadData()
          }
        })
    } else {
      changeAlternativeProp("numForecastYears", value, true)
      await api.updateAlternativeForecastDates(
        currentUser.userID,
        alternativeId,
        "keep",
        alternative.fyStart,
        value
      )
      await loadData()
    }
  }

  const changeFiscalYear = async value => {
    if (value > 0)
      swalWith3Buttons
        .fire({
          title: ``,
          icon: "warning",
          text: "How should the system handle the existing forecast data?",
          footer:
            "Note: if you keep data in the years entered, any data outside the new range will be removed.",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: "Move the data",
          denyButtonText: "Keep the data in the years entered.",
          cancelButtonText: "Cancel",
        })
        .then(async result => {
          if (result.isConfirmed || result.isDenied) {
            changeAlternativeProp("fyStart", value, true)
            await api.updateAlternativeForecastDates(
              currentUser.userID,
              alternativeId,
              result.isConfirmed ? "move" : "keep",
              value,
              alternative.numForecastYears
            )
            await loadData()
          }
        })
  }

  return (
    <React.Fragment>
      {isLoading && costTable1.length == 0 && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {(!isLoading || costTable1.length > 0) && (
        <React.Fragment>
          <Row>
            <Col sm="3">
              <div>
                <Label className="form-label">Starting Fiscal Year</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={alternative.fyStart || -1}
                  onChange={e => {
                    changeFiscalYear(e.target.value)
                  }}
                  disabled={currentUser.roleID < 10 && "true"}
                >
                  <option value={-1}>Select...</option>
                  {fiscalYears.map((a, idx) => {
                    return (
                      <option key={idx} value={a}>
                        FY {a}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Col>
            <Col sm="3">
              <div>
                <Label className="form-label">
                  Planning Horizon (in Years)
                </Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={alternative.numForecastYears}
                  disabled={currentUser.roleID < 10 && "true"}
                  onChange={e => {
                    changeNumberOfYears(e.target.value)
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((a, idx) => {
                    return (
                      <option key={idx} value={a}>
                        {a}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Col>
            <Col sm="3">
              <div>
                <Label className="form-label">Expense Type</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={alternative.colorOfMoney || -1}
                  onChange={e => {
                    changeAlternativeProp("colorOfMoney", e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {expenseOptions.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemName}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Col>
            <Col sm="3">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck4"
                    checked={showResourceEstimates}
                    onClick={e => {
                      setShowResourceEstimates(!showResourceEstimates)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck4">
                    Show Resource Estimate Data
                  </Label>
                </div>
                {currentUser.roleID >= 10 && (
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    style={{ float: "right" }}
                    onClick={clearForecast}
                  >
                    <i className="fas fa-eraser"></i> Clear Forecast
                  </button>
                )}
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div className="d-flex-row" style={{ gap: "20px" }}>
                  <div className="d-flex-col">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={alternative.useDiscount}
                        style={{
                          backgroundColor: alternative.useDiscount
                            ? "green"
                            : "transparent",
                        }}
                        onClick={() => {
                          changeAlternativeProp(
                            "useDiscount",
                            !alternative.useDiscount
                          )
                          setUseRates(false)
                        }}
                      />
                      <Label check style={{ marginLeft: "10px" }}>
                        Apply Discount Rates
                      </Label>
                    </FormGroup>
                    {alternative.useDiscount && (
                      <div
                        style={{
                          marginLeft: "50px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          className="form-control align-end"
                          type="number"
                          style={{ padding: "5px 2px", width: "80px" }}
                          value={alternative.discount1}
                          onChange={e => {
                            changeAlternativeProp("discount1", e.target.value)
                          }}
                        />
                        <span>
                          <b>%</b>
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex-col">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={alternative.applyInflation}
                        style={{
                          backgroundColor: alternative.applyInflation
                            ? "green"
                            : "transparent",
                        }}
                        onClick={() => {
                          changeAlternativeProp(
                            "applyInflation",
                            !alternative.applyInflation
                          )
                          setUseRates(false)
                        }}
                      />
                      <Label check style={{ marginLeft: "10px" }}>
                        Apply Inflation Rates
                      </Label>
                    </FormGroup>
                    {alternative?.applyInflation == true && (
                      <div>
                        <Label className="form-label">Year Planned</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={
                            alternative.yearPlanned == 0
                              ? alternative.fyStart - 1
                              : alternative.yearPlanned
                          }
                          onChange={e => {
                            changeAlternativeProp("yearPlanned", e.target.value)
                          }}
                        >
                          {Array.from(
                            { length: 6 },
                            (_, index) => index + 1
                          ).map(i => (
                            <option key={i} value={alternative.fyStart - i}>
                              {alternative.fyStart - i}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  {(alternative?.useDiscount == true ||
                    alternative?.applyInflation == true) && (
                      <FormGroup switch>
                        <Input
                          type="switch"
                          checked={useRates}
                          style={{
                            backgroundColor: useRates ? "green" : "transparent",
                          }}
                          onClick={() => setUseRates(!useRates)}
                        />
                        <Label check style={{ marginLeft: "10px" }}>
                          View Cost Pools with Rates Applied
                        </Label>
                      </FormGroup>
                    )}
                </div>
              </div>

              <HelpIconWithModal itemId={3} />
            </div>
          </Row>
          {scenario?.costMultiplier == 1000 && (
            <Label style={{ marginTop: "10px" }}>Enter Amounts in $000's</Label>
          )}
          {isLoading && costTable1.length > 0 && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          <Row style={{ marginTop: "20px" }}>
            <Col md={showResourceEstimates ? 6 : 12}>
              {costPoolYears.length > 0 && (
                <table
                  className="table table-bordered lower-padding-table"
                  style={{ backgroundColor: "white", fontSize: "12px" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "18%" }}></th>
                      {costPoolYears.map((y, idx) => (
                        <th
                          key={idx}
                          style={{ width: `${35 / costPoolYears.length}%` }}
                        >
                          FY {y.yearID}
                        </th>
                      ))}

                      <th style={{ width: "7%" }}>Total</th>
                      {!showResourceEstimates && (
                        <th style={{ width: "40%" }}>Notes</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {getTableRowsToRender(costTable1, "costTable1")}
                    {getTableRowsToRender(costTable2, "costTable2")}
                    {getTableRowsToRender(costTable3, "costTable3")}
                    <tr
                      style={{ backgroundColor: "lightgray", fontWeight: 600 }}
                    >
                      <td style={{ textAlign: "Right", fontWeight: 600 }}>
                        Total Cost
                      </td>
                      {costPoolYears.map((y, idx) => (
                        <td
                          key={idx}
                          className="align-end"
                          style={{ fontWeight: "bold" }}
                        >
                          {currencyFormatter.format(
                            getYearTotals(
                              [...costTable1, ...costTable2, ...costTable3],
                              y
                            )
                          )}
                        </td>
                      ))}
                      <td className="align-end" style={{ fontWeight: "bold" }}>
                        {currencyFormatter.format(
                          [...costTable1, ...costTable2, ...costTable3].reduce(
                            (acc, item) => acc + item.totalAmount,
                            0
                          )
                        )}
                      </td>
                      {!showResourceEstimates && <td></td>}
                    </tr>
                    <tr>
                      <th style={{ width: "18%" }}></th>
                      {costPoolYears.map((y, idx) => (
                        <th
                          key={idx}
                          style={{ width: `${35 / costPoolYears.length}%` }}
                        >
                          FY {y.yearID}
                        </th>
                      ))}

                      <th style={{ width: "7%" }}>Total</th>
                      {!showResourceEstimates && (
                        <th style={{ width: "40%" }}>Notes</th>
                      )}
                    </tr>
                    {getTableRowsToRender(benefitTable1, "benefitTable1")}
                    {getTableRowsToRender(benefitTable2, "benefitTable2")}
                    {getTableRowsToRender(benefitTable3, "benefitTable3")}
                    <tr
                      style={{ backgroundColor: "lightgray", fontWeight: 600 }}
                    >
                      <td style={{ textAlign: "right" }}>Total Benefit</td>
                      {costPoolYears.map((y, idx) => (
                        <td key={idx} className="align-end">
                          {currencyFormatter.format(
                            getYearTotals(
                              [
                                ...benefitTable1,
                                ...benefitTable2,
                                ...benefitTable3,
                              ],
                              y
                            )
                          )}
                        </td>
                      ))}

                      <td className="align-end" style={{ fontWeight: "bold" }}>
                        {currencyFormatter.format(
                          [
                            ...benefitTable1,
                            ...benefitTable2,
                            ...benefitTable3,
                          ].reduce((acc, item) => acc + item.totalAmount, 0)
                        )}
                      </td>
                      {!showResourceEstimates && <td></td>}
                    </tr>
                  </tbody>
                </table>
              )}
              <h3>
                <b>
                  Summary {alternative.useDiscount && " - Net Present Value"}{" "}
                  {alternative.applyInflation && " - Inflation Applied"}{" "}
                  {scenario?.costMultiplier == 1000 && "($000’s)"}
                </b>
              </h3>
              {forecastSummary.length > 0 && (
                <table
                  className="table table-bordered table-responsive lower-padding-table"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      {Object.keys(forecastSummary[0])
                        .filter(x => x.startsWith("c-"))
                        .map((k, idx) => (
                          <th
                            style={{
                              textAlign:
                                parseInt(k.replace("c-", "")) !== NaN
                                  ? "center"
                                  : "start",
                            }}
                            key={idx}
                          >
                            {k.replace("c-", "") == "Cost Pool"
                              ? ""
                              : k.replace("c-", "")}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {forecastSummary.map((f, i) => {
                      return (
                        <tr
                          key={i}
                          style={{
                            backgroundColor: f.backColor,
                          }}
                        >
                          {Object.keys(f)
                            .filter(x => x.startsWith("c-"))
                            .map((k, idx) => (
                              <td
                                key={idx}
                                className={!isNaN(f[k]) ? "align-end" : ""}
                                style={{
                                  fontWeight:
                                    idx == 0 || f.bold == "1" ? 600 : 100,
                                }}
                              >
                                {idx == 0
                                  ? f[k]
                                  : f.format == "$"
                                  ? currencyFormatter.format(f[k])
                                  : f.format == "%"
                                  ? `${f[k]}%`
                                  : f[k]}
                              </td>
                            ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </Col>
            {showResourceEstimates && resourceEstimateData.length > 0 && (
              <Col md="6">
                <table
                  className="table table-bordered table-responsive lower-padding-table"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}></th>
                      {Object.keys(resourceEstimateData[0])
                        .filter(x => x.startsWith("c-"))
                        .map((k, idx) => (
                          <th key={idx}>{k.replace("c-", "")}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {resourceEstimateData.map((r, idx) => (
                      <tr key={idx}>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            style={{
                              paddingTop: "1px",
                              paddingBottom: "1px",
                              fontSize: "10px",
                            }}
                            onClick={() => copyBudgetToForecast(r.costPoolID)}
                          >
                            <i className="fas fa-chevron-left"></i> Copy
                          </button>
                        </td>
                        {Object.keys(r)
                          .filter(x => x.startsWith("c-"))
                          .map((k, idx) => (
                            <td
                              key={idx}
                              className={!isNaN(r[k]) ? "align-end" : ""}
                            >
                              {!isNaN(r[k])
                                ? currencyFormatter.format(r[k])
                                : r[k]}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-primary"
                  onClick={copyAllBudgetsToForecast}
                >
                  <i className="fas fa-chevron-left"></i> Copy All Resource
                  Estimate Items
                </button>
              </Col>
            )}
          </Row>
          <Row>
            <Col></Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ForecastSection
