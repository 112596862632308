import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { ReactTabulator } from "react-tabulator"
import { toast } from "react-toastify"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { currencyFormatter } from "utils/formatters"
import CreateContractModal from "./CreateContractModal"
import useModal from "hooks/useModalHook"
import PaymentModal from "./Modals/PaymentModal"
import CreateContractItemNoticeModal from "./Modals/CreateContractItemNoticeModal"
import { getAlternativeYears } from "utils/alternativeHelper"

const ContractSection = ({
  activeTab,
  alternativeId,
  alternative,
  triggerSave,
  setTriggerSave,
  enterprise,
  scenario,
}) => {
  const currentUser = userService.getLoggedInUser()
  const tabulatorRef = useRef()
  const [contractForecasts, setContractForecasts] = useState([])
  const [contractCompares, setContractCompares] = useState([])
  const [contractItems, setContractItems] = useState([])
  const [strategyOptions, setStrategyOptions] = useState([])
  const [contractEntityOptions, setContractEntityOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [resourceFlowContracts, setResourceFlowContracts] = useState([])
  const [showResourceEstimates, setShowResourceEstimates] = useState(false)
  const [paymentToEdit, setPaymentToEdit] = useState(null)
  const [contractItemNoticeToEdit, setContractItemNoticeToEdit] = useState(null)
  const [payments, setPayments] = useState([])
  const [comparisonByObligations, setComparisonByObligations] = useState(true)
  const [contractComparePayments, setContractComparePayments] = useState([])

  const [totalsByYear, setTotalsByYear] = useState(true)
  const [totalsByCostPool, setTotalsByCostPool] = useState(false)

  const {
    show: showContractItemNoticeModal,
    toggle: toggleContractItemNoticeModal,
  } = useModal()

  const newPayment = {
    paymentID: -1,
    contractID: -1,
    alternativeID: alternativeId,
    paymentNumber: "",
    poNumber: "",
    approvalDate: new Date(),
    paymentDate: new Date(),
    requestDate: new Date(),
    paymentStatus: "In Progress",
    paymentPerson: "",
    bcItemContractID: -1,
    notes: "",
    paymentAmount: 0,
  }

  const newContractItemNotice = {
    contractItemID: -1,
  }

  const [selectedResourceFlowContract, setSelectedResourceFlowContract] =
    useState(null)

  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const { show: showPaymentModal, toggle: togglePaymentModal } = useModal()

  useEffect(() => {
    if (activeTab == "Contract") {
      loadData()
    }
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (triggerSave) {
      saveData()
    }
  }, [triggerSave])

  useEffect(() => {
    if (activeTab == "Contract") {
      loadComparisonData()
    }
  }, [activeTab, comparisonByObligations])

  useEffect(() => {
    if (enterprise != undefined && enterprise != null) {
      if (enterprise.obligationForm.toLowerCase() == "multi") {
        setTotalsByYear(true)
      } else {
        setTotalsByYear(true)
      }
    }
  }, [enterprise])

  const loadComparisonData = async () => {
    if (comparisonByObligations) {
      let compares = await api.getContractCompare(alternativeId)
      setContractCompares(compares)
    } else {
      let compares = await api.getContractComparePayment(alternativeId)
      setContractComparePayments(compares)
    }
  }

  const loadData = async () => {
    let forecast = await api.getContractForecast(alternativeId)
    setContractForecasts(forecast)

    let str = await api.getScenarioList(alternative.scenarioID, "Strategy")
    setStrategyOptions(str)
    let ce = await api.getContractEntities(alternative.scenarioID)
    setContractEntityOptions(ce)
    await loadContractItems()
    await loadResourceFlowContracts()
    await loadPayments()
  }

  const loadPayments = async () => {
    let p = await api.getPayments(alternativeId)
    setPayments(p)
  }

  const reloadFromPaymentUpdate = async () => {
    await loadPayments()
    await loadComparisonData()
  }

  const reloadFromContractObligationUpdate = async () => {
    await loadData()
    await loadComparisonData()
  }

  const loadResourceFlowContracts = async () => {
    let rfc = await api.getResourceFlowContracts(
      currentUser.userID,
      alternativeId
    )
    setResourceFlowContracts(rfc)
  }

  const loadContractItems = async () => {
    let resourceGroupLists = []
    let items = await api.getContractItems(alternativeId)
    if (
      enterprise != null &&
      enterprise.obligationForm.toLowerCase() == "multi"
    ) {
      let uniqueResourceGroupIds = [
        ...new Set(items.map(x => x.resourceGroupID)),
      ]
      let tasks = uniqueResourceGroupIds.map(
        async rgId =>
          await api.getContractsForResource(alternative.scenarioID, rgId)
      )
      let contractsByResourceGroup = await Promise.all(tasks)
      uniqueResourceGroupIds.forEach((rgId, idx) => {
        resourceGroupLists.push({
          resourceGroupID: rgId,
          contracts:
            contractsByResourceGroup.length >= idx
              ? contractsByResourceGroup[idx]
              : [],
        })
      })

      items.forEach(item => {
        let rgl = resourceGroupLists.find(
          x => x.resourceGroupID == item.resourceGroupID
        )
        if (rgl != undefined) {
          item.contractOptions = rgl.contracts
        }
      })
    }

    setContractItems(items)
  }

  const saveData = async () => {
    try {
      let rowsToUpdate = contractItems.filter(x => x.isDirty)
      if (rowsToUpdate.length > 0) {
        let rowsToUpdateTasks = rowsToUpdate.map(x => async () => {
          let payload = { ...x }
          payload.contractItemYears.forEach(y => {
            y.contractItemID = payload.contractItemID
          })
          return await api.updateContractItem(currentUser.userID, payload)
        })
        await Promise.all(rowsToUpdateTasks.map(t => t()))
        await loadData()
        await loadComparisonData()
        setTriggerSave(false)
        toast.success(`Contract items updated successfuly`)
      } else {
        setTriggerSave(false)
      }
    } catch {
      setTriggerSave(false)
    }
  }

  const renderTable = (tableItems, shouldColorZeroValues, showYears) => {
    const years =
      tableItems.length == 0
        ? []
        : Object.keys(tableItems[0])
            .filter(x => x.startsWith("c-"))
            .map(x => {
              return x.replace("c-", "")
            })
            .filter(x => !isNaN(x))
    return (
      <table
        className="table table-bordered lower-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          {showYears && (
            <tr>
              <th style={{ width: "20%" }}>Phase</th>
              <th style={{ width: "20%" }}>Cost Pool</th>
              {years.map((y, idx) => (
                <th key={idx} style={{ width: `${50 / years.length}%` }}>
                  FY {y}
                </th>
              ))}
              {/* <th style={{ width: "10%" }}>FY {alternative.fyStart}</th>
              <th style={{ width: "10%" }}>FY {alternative.fyStart + 1}</th>
              <th style={{ width: "10%" }}>FY {alternative.fyStart + 2}</th>
              <th style={{ width: "10%" }}>FY {alternative.fyStart + 3}</th>
              <th style={{ width: "10%" }}>FY {alternative.fyStart + 4}</th> */}
              <th style={{ width: "10%" }}>Total</th>
            </tr>
          )}
          {!showYears && (
            <tr>
              <th style={{ width: "20%" }}>Phase</th>
              <th style={{ width: "20%" }}>Cost Pool</th>
              <th style={{ width: "10%" }}>Total</th>
            </tr>
          )}
        </thead>
        <tbody>
          {tableItems.map((c, idx) => {
            return (
              <tr
                key={idx}
                style={{
                  fontWeight: c.bcItemName == "Total" ? "bold" : "initial",
                  backgroundColor:
                    c.bcItemName == "Total" ? "lightgray" : "transparent",
                }}
              >
                <td>{c["c-Phase"]}</td>
                <td>{c["c-Cost Pool"]}</td>
                {showYears &&
                  years.map((y, idx) => (
                    <td
                      key={idx}
                      className="align-end"
                      style={
                        shouldColorZeroValues &&
                        (c[`c-${y}`] == 0 || c[`c-${y}`] == null)
                          ? { backgroundColor: "green", color: "white" }
                          : c[`c-${y}`] < 0
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {currencyFormatter.format(c[`c-${y}`])}
                    </td>
                  ))}
                <td
                  className="align-end"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "lightgray",
                    color: c.total < 0 ? "red" : "initial",
                  }}
                >
                  {currencyFormatter.format(c["c-Total"])}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const changeContractItem = (contractItem, prop, value) => {
    let listCopy = [...contractItems]
    let original = listCopy.find(
      x => x.contractItemID == contractItem.contractItemID
    )
    original[prop] = value
    original.isDirty = true
    setContractItems(listCopy)

    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            document.getElementById("saveAlternative").click()
          }}
        >
          <i className="fas fa-save"></i> Save
        </button>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const getRowTotal = contractItem => {
    return contractItem.contractItemYears.reduce(
      (acc, item) => acc + getNumberDefaultZero(item.amount),
      0
    )
  }

  const getNumberDefaultZero = num => {
    if (num == undefined || num == null || num == "") {
      return 0
    }
    return num
  }

  const deleteContractItem = async contractItem => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this item?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteContractItem(
            currentUser.userID,
            contractItem.contractItemID
          )
          loadContractItems()
        }
      })
  }

  const getPaymentsTotal = year => {
    let total = 0

    if (year == "Total") {
      payments.forEach(p => {
        total += p.paymentAmount
      })
    } else {
      payments.forEach(p => {
        if (moment(p.paymentDate).year() == year) {
          total += p.paymentAmount
        }
      })
    }

    return currencyFormatter.format(total)
  }

  const alternativeYears = getAlternativeYears(alternative)

  return (
    <React.Fragment>
      <Row className="mb-3">
        {scenario?.costMultiplier == 1000 && (
          <Label style={{ marginBottom: "10px" }}>Enter Amounts in $000’s</Label>
        )}
        <Col style={{ display: "flex" }}>
          <FormGroup switch>
            <Input
              type="switch"
              checked={totalsByYear}
              style={{
                backgroundColor: totalsByYear ? "green" : "transparent",
              }}
              onClick={() => setTotalsByYear(!totalsByYear)}
            />
            <Label check style={{ marginLeft: "10px" }}>
              Total by Year
            </Label>
          </FormGroup>
          {/*           <FormGroup switch style={{ marginLeft: "20px" }}>
            <Input
              type="switch"
              checked={totalsByCostPool}
              style={{
                backgroundColor: totalsByCostPool ? "green" : "transparent",
              }}
              onClick={() => setTotalsByCostPool(!totalsByCostPool)}
            />
            <Label check style={{ marginLeft: "10px" }}>
              Total by Cost Pool
            </Label>
          </FormGroup> */}
        </Col>
      </Row>
      {contractForecasts.length > 0 && (
        <Row>
          <Col>
            <h5>
              <b>Contract $ from Forecast Tab</b>
            </h5>
            {renderTable(contractForecasts, false, totalsByYear)}
          </Col>
        </Row>
      )}
      {contractCompares.length > 0 &&
        enterprise.obligationForm.toLowerCase() == "multi" && (
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <h5 style={{ margin: 0 }}>
                  <b>Contract $ Remaining to be Assigned</b>
                </h5>
                {enterprise.obligationForm.toLowerCase() !== "multi" && (
                  <div>
                    <input
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      type="radio"
                      id="radio1"
                      name="radio1"
                      checked={comparisonByObligations == true}
                      onClick={e => {
                        setComparisonByObligations(true)
                      }}
                    />
                    <label
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      className="me-1"
                      htmlFor="radio1"
                    >
                      {enterprise.obligationLabel}
                    </label>
                    <input
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      type="radio"
                      id="radio2"
                      name="radio2"
                      checked={comparisonByObligations == false}
                      onClick={e => {
                        setComparisonByObligations(false)
                      }}
                    />
                    <label
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      className="me-1"
                      htmlFor="radio2"
                    >
                      {enterprise != null && enterprise.paymentLabel + "s"}
                    </label>
                  </div>
                )}
              </div>

              {comparisonByObligations &&
                renderTable(contractCompares, true, totalsByYear)}
              {!comparisonByObligations &&
                renderTable(contractComparePayments, true, totalsByYear)}
            </Col>
          </Row>
        )}

      <Row className="mb-3 mt-3">
        <Col>
          {enterprise != null &&
            enterprise.obligationForm.toLowerCase() == "multi" && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSelectedResourceFlowContract({ businessCaseItemID: -1 })
                  toggleModal()
                }}
              >
                + Add New {enterprise != null && enterprise.obligationLabel}
              </button>
            )}
          {(enterprise == null ||
            enterprise.obligationForm.toLowerCase() !== "multi") && (
            <button
              className="btn btn-primary"
              onClick={() => {
                setContractItemNoticeToEdit({ ...newContractItemNotice })
                toggleContractItemNoticeModal()
              }}
            >
              + Add New {enterprise != null && enterprise.obligationLabel}
            </button>
          )}
        </Col>
      </Row>

      {enterprise != null &&
        enterprise.obligationForm.toLowerCase() == "multi" &&
        contractItems.length > 0 && (
          <Row>
            <Col>
              <h5>
                <b>
                  {enterprise != null &&
                    enterprise.obligationLabel + " $ Assigned"}
                </b>
              </h5>
              <table
                className="table table-bordered lower-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ width: "12%" }}>Phase - Cost Pool</th>
                    <th style={{ width: "12%" }}>Contract</th>
                    <th style={{ width: "12%" }}>Entity Type - Entity Name</th>
                    <th style={{ width: "12%" }}>Acquisition Approach</th>
                    <th>PoP Start</th>
                    <th>PoP End</th>
                    {totalsByYear &&
                      alternativeYears.map((y, idx) => (
                        <th key={idx}>FY {y}</th>
                      ))}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {contractItems.map((c, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => deleteContractItem(c)}
                          ></i>
                        </td>
                        <td>{c.costPoolName}</td>
                        <td>
                          {c.contractOptions !== undefined && (
                            <select
                              className="form-control form-select select2 mb-xxl-0"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                              value={c.contractID}
                              onChange={e => {
                                changeContractItem(
                                  c,
                                  "contractID",
                                  e.target.value
                                )
                              }}
                            >
                              {c.contractOptions.map((a, idx) => {
                                return (
                                  <option key={idx} value={`${a.contractID}`}>
                                    {a.contractName}
                                  </option>
                                )
                              })}
                            </select>
                          )}
                        </td>
                        <td>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                            value={c.contractEntityID}
                            onChange={e => {
                              changeContractItem(
                                c,
                                "contractEntityID",
                                e.target.value
                              )
                            }}
                          >
                            {contractEntityOptions.map((a, idx) => {
                              return (
                                <option
                                  key={idx}
                                  value={`${a.contractEntityID}`}
                                >
                                  {a.contractEntityType} -{" "}
                                  {a.contractEntityName}
                                </option>
                              )
                            })}
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                            value={c.strategyID}
                            onChange={e => {
                              changeContractItem(
                                c,
                                "strategyID",
                                e.target.value
                              )
                            }}
                          >
                            {strategyOptions.map((a, idx) => {
                              return (
                                <option key={idx} value={`${a.listItemID}`}>
                                  {a.listItemName}
                                </option>
                              )
                            })}
                          </select>
                        </td>
                        <td>
                          <Input
                            name="startDate"
                            type="date"
                            style={{ padding: "0px 0px" }}
                            onChange={e =>
                              changeContractItem(
                                c,
                                "startDate",
                                moment(e.target.value, "YYYY-MM-DD").format(
                                  "YYYY-MM-DDThh:mm:ss"
                                )
                              )
                            }
                            value={moment(c.startDate).format("YYYY-MM-DD")}
                          />
                        </td>
                        <td>
                          <Input
                            name="endDate"
                            type="date"
                            style={{ padding: "0px 0px" }}
                            onChange={e =>
                              changeContractItem(
                                c,
                                "endDate",
                                moment(e.target.value, "YYYY-MM-DD").format(
                                  "YYYY-MM-DDThh:mm:ss"
                                )
                              )
                            }
                            value={moment(c.endDate).format("YYYY-MM-DD")}
                          />
                        </td>
                        {totalsByYear &&
                          c.contractItemYears.map((year, idx) => (
                            <td key={idx} className="align-end">
                              <CurrencyInput
                                className="form-control align-end"
                                style={{ padding: "0px 0px" }}
                                value={year.amount}
                                onValueChange={(value, name, values) => {
                                  let yearsCopy = [...c.contractItemYears]
                                  let currentYear = yearsCopy.find(
                                    x => x.yearID == year.yearID
                                  )
                                  currentYear.amount = values.float
                                  changeContractItem(
                                    c,
                                    "contractItemYears",
                                    yearsCopy
                                  )
                                }}
                                prefix={"$"}
                              />
                            </td>
                          ))}

                        <td className="align-end">
                          {currencyFormatter.format(getRowTotal(c))}
                        </td>
                      </tr>
                    )
                  })}
                  <tr
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "lightgray",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {totalsByYear &&
                      alternativeYears.map((y, idx) => (
                        <td key={idx} className="align-end">
                          {currencyFormatter.format(
                            contractItems.reduce(
                              (acc, item) =>
                                acc +
                                (item.contractItemYears.find(
                                  cy => cy.yearID == y
                                )?.amount || 0),
                              0
                            )
                          )}
                        </td>
                      ))}
                    <td className="align-end">
                      {currencyFormatter.format(
                        contractItems.reduce(
                          (acc, item) => acc + getRowTotal(item),
                          0
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        )}
      {(enterprise == null ||
        enterprise.obligationForm.toLowerCase() !== "multi") &&
        contractItems.length > 0 && (
          <Row>
            <Col>
              <h5>
                <b>
                  {enterprise != null &&
                    enterprise.obligationLabel + " $ Assigned"}
                </b>
              </h5>
              <table
                className="table table-bordered lower-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Contract</th>
                    <th>Approval Date</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Vendor</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {contractItems.map((c, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              setContractItemNoticeToEdit(c)
                              toggleContractItemNoticeModal()
                            }}
                          >
                            {c.contractItemNum}
                          </a>
                        </td>
                        <td>{c.contractName}</td>
                        <td>
                          {c.approvalDate == null
                            ? ""
                            : moment(c.approvalDate).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          {c.startDate == null
                            ? ""
                            : moment(c.startDate).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          {c.endDate == null
                            ? ""
                            : moment(c.endDate).format("MM/DD/YYYY")}
                        </td>
                        <td>{c.vendor}</td>
                        <td>{c.comments}</td>
                        <td>{c.contractItemStatus}</td>
                        <td className="align-end">
                          {c.totalAmount &&
                            currencyFormatter.format(c.totalAmount)}
                        </td>
                      </tr>
                    )
                  })}
                  <tr
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "lightgray",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="align-end">
                      {currencyFormatter.format(
                        contractItems.reduce(
                          (acc, item) => acc + (item.totalAmount || 0),
                          0
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        )}

      {enterprise != null && enterprise.paymentLabel != "" && (
        <Row className="mb-3 mt-3">
          <Col>
            <button
              className="btn btn-primary"
              onClick={() => {
                setPaymentToEdit({ ...newPayment })
                togglePaymentModal()
              }}
            >
              + Add New {enterprise != null && enterprise.paymentLabel}
            </button>
          </Col>
        </Row>
      )}

      {enterprise != null &&
        enterprise.paymentLabel != "" &&
        payments.length > 0 && (
          <Row>
            <Col>
              <h5>
                <b>{enterprise.paymentLabel + "s"}</b>
              </h5>
              <table
                className="table table-bordered lower-padding-table"
                style={{ backgroundColor: "white" }}
              >
                {totalsByYear ? (
                  <thead>
                    <tr>
                      <th>{enterprise != null && enterprise.paymentLabel} #</th>
                      <th>Date</th>
                      <th>PO</th>
                      <th>Notes</th>
                      {alternativeYears.map((y, idx) => (
                        <th key={idx}>FY {y}</th>
                      ))}

                      <th>Total</th>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      <th>{enterprise != null && enterprise.paymentLabel} #</th>
                      <th>Date</th>
                      <th>PO</th>
                      <th>Notes</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                )}

                {totalsByYear ? (
                  <tbody>
                    {payments.map((p, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e && e.preventDefault()
                              setPaymentToEdit({ ...p })
                              togglePaymentModal()
                            }}
                          >
                            {p.paymentNumber}
                          </a>
                        </td>
                        <td>{moment(p.paymentDate).format("MM/DD/YYYY")}</td>
                        <td>{p.poNumber}</td>
                        <td>{p.notes}</td>
                        {alternativeYears.map((y, idx) => (
                          <td key={idx} className="align-end">
                            {moment(p.paymentDate).year() == y &&
                              currencyFormatter.format(p.paymentAmount)}
                          </td>
                        ))}

                        <td className="align-end">
                          {currencyFormatter.format(p.paymentAmount)}
                        </td>
                      </tr>
                    ))}
                    {payments.length > 0 && (
                      <tr
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <td>
                          <b>Totals</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="align-end">
                          {getPaymentsTotal(alternative.fyStart)}
                        </td>
                        <td className="align-end">
                          {getPaymentsTotal(alternative.fyStart + 1)}
                        </td>
                        <td className="align-end">
                          {getPaymentsTotal(alternative.fyStart + 2)}
                        </td>
                        <td className="align-end">
                          {getPaymentsTotal(alternative.fyStart + 3)}
                        </td>
                        <td className="align-end">
                          {getPaymentsTotal(alternative.fyStart + 4)}
                        </td>
                        <td className="align-end">
                          {getPaymentsTotal("Total")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {payments.map((p, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e && e.preventDefault()
                              setPaymentToEdit({ ...p })
                              togglePaymentModal()
                            }}
                          >
                            {p.paymentNumber}
                          </a>
                        </td>
                        <td>{moment(p.paymentDate).format("MM/DD/YYYY")}</td>
                        <td>{p.poNumber}</td>
                        <td>{p.notes}</td>
                        <td>{currencyFormatter.format(p.paymentAmount)}</td>
                      </tr>
                    ))}
                    {payments.length > 0 && (
                      <tr
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <td>
                          <b>Totals</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{getPaymentsTotal("Total")}</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </Col>
          </Row>
        )}
      <Row>
        <Col>
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              id="rscEstimateData"
              name="rscEstimateData"
              checked={showResourceEstimates}
              onClick={e => {
                setShowResourceEstimates(!showResourceEstimates)
              }}
            />
            <Label className="form-check-label" for="rscEstimateData">
              Show Resource Estimate Data
            </Label>
          </div>
          {showResourceEstimates && (
            <table
              className="table table-bordered lower-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  <th>Resource</th>
                  <th>Cost Pool</th>
                  <th>Contract</th>
                  <th>Entity</th>
                  <th>Approach</th>
                  <th>PoP Start</th>
                  <th>PoP End</th>
                  {alternativeYears.map((y, idx) => (
                    <th key={idx}>FY {y}</th>
                  ))}

                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {resourceFlowContracts.map((r, idx) => (
                  <tr key={idx}>
                    <td>{r.resourceGroupName}</td>
                    <td>{r.bcLineItemName}</td>
                    <td>{r.contractName}</td>
                    <td>{r.contractEntity}</td>
                    <td>{r.strategy}</td>
                    <td>{r.defaultStart}</td>
                    <td>{r.defaultEnd}</td>
                    {alternativeYears.map((y, idx) => (
                      <td key={idx} className="text-end">
                        {currencyFormatter.format(r[`c-${y}`] || 0)}
                      </td>
                    ))}

                    <td className="text-end">
                      {currencyFormatter.format(r["c-Total"] || 0)}
                    </td>
                    <td>
                      {r.showAdd == 1 && (
                        <button
                          className="btn btn-primary btn-sm"
                          style={{
                            paddingTop: "1px",
                            paddingBottom: "1px",
                            fontSize: "10px",
                          }}
                          onClick={() => {
                            setSelectedResourceFlowContract(r)
                            toggleModal()
                          }}
                        >
                          Add
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {showResourceEstimates && resourceFlowContracts.length == 0 && (
            <p>No Data</p>
          )}
        </Col>
      </Row>
      <CreateContractModal
        alternative={alternative}
        contractEntityOptions={contractEntityOptions}
        strategyOptions={strategyOptions}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        reloadContracts={reloadFromContractObligationUpdate}
        preselectedData={selectedResourceFlowContract}
      />
      <CreateContractItemNoticeModal
        alternative={alternative}
        isOpen={showContractItemNoticeModal}
        toggleModal={toggleContractItemNoticeModal}
        contractItemNoticeToEdit={contractItemNoticeToEdit}
        reload={loadContractItems}
        enterprise={enterprise}
      />
      <PaymentModal
        isOpen={showPaymentModal}
        toggleModal={togglePaymentModal}
        contractItems={contractItems}
        paymentToEdit={paymentToEdit}
        reload={reloadFromPaymentUpdate}
        enterprise={enterprise}
      />
    </React.Fragment>
  )
}

export default ContractSection
