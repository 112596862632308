import AlternativeRatingTable from "pages/_AlternativeShared/AlternativeRatingTable"
import { getAlternativeScoresChartOptionsSeries } from "pages/_AlternativeShared/alternativeChartFunctions"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Loader from "react-loaders"
import { Button, ButtonGroup, FormGroup, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadUsersAvatars } from "utils/avatar"
import colors from "utils/colors"

const ScoringResultSection = ({
  alternativeId,
  activeTab,
  scenarioId,
  scenario,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [alternativeScores, setAlternativeScores] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showGrids, setShowGrids] = useState(true)
  const [showCharts, setShowCharts] = useState(false)
  const [scenarioRatingScale, setScenarioRatingScale] = useState([])
  const [showAltModal, setShowAltModal] = useState(false)
  const [usersPhotos, setUsersPhotos] = useState([])

  const [scenarioChildTypes, setScenarioChildTypes] = useState([])
  const [selectedScenarioId, setSelectedScenarioId] = useState(-1)

  useEffect(() => {
    if (activeTab == "ScoringResults") {
      loadData()
    }
  }, [activeTab])

  useEffect(() => {
    if (activeTab == "ScoringResults") {
      setSelectedScenarioId(scenarioId)
    }
  }, [activeTab, scenarioId, alternativeId])

  useEffect(() => {
    if (selectedScenarioId > -1) {
      loadScenarioRatingScale(selectedScenarioId)
      loadAltData(alternativeId, selectedScenarioId)
    }
  }, [selectedScenarioId])

  const loadData = async () => {
    let sct = await api.getList(currentUser.userID, "ScenarioChildType")
    setScenarioChildTypes(sct)
  }

  const loadAltData = async (altId, scenarioId) => {
    setIsLoading(true)
    let altScores = await api.getAltScores(altId, scenarioId)
    let userScoreTasks = altScores.map(s => async () => {
      return await api.getAltUserScores(s.altScoreID, s.levelID)
    })
    let userScores = await Promise.all(userScoreTasks.map(t => t()))

    if (usersPhotos.length == 0) {
      let flatUserScores = userScores.flat()
      let avatars = await loadUsersAvatars(flatUserScores.map(x => x.userID))
      setUsersPhotos(avatars)
    }

    for (var i = 0; i < altScores.length; i++) {
      altScores[i].userScores = userScores[i]
      altScores[i].chartData = getAlternativeScoresChartOptionsSeries(
        userScores[i],
        scenarioRatingScale
      )
    }

    setAlternativeScores(altScores)
    setIsLoading(false)
  }

  const loadScenarioRatingScale = async sid => {
    try {
      let scenarioRatings = await api.getScenarioInputRatings(sid)
      let scenarioRatingsSorted = scenarioRatings.sort(
        (a, b) => parseFloat(a.inputValue) - parseFloat(b.inputValue)
      )
      setScenarioRatingScale(scenarioRatingsSorted)
      return scenarioRatingsSorted
    } catch (err) {
      console.log(err)
    }
  }

  const toggleAltModal = () => {
    setShowAltModal(!showAltModal)
  }

  return (
    <React.Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        ></div>
      </Row>

      {scenario != null && (
        <>
          <ButtonGroup>
            <button
              className={`btn  ${
                selectedScenarioId == scenario.scenarioID
                  ? " btn-primary"
                  : " btn-outline-secondary"
              }`}
              onClick={() => setSelectedScenarioId(scenario.scenarioID)}
            >
              {scenario.scoreName}
            </button>
            {scenario?.secondaryScenarios?.map((s, idx) => (
              <button
                key={idx}
                className={`btn ${
                  s.scenarioID == selectedScenarioId
                    ? " btn-primary"
                    : " btn-outline-secondary"
                }`}
                onClick={() => setSelectedScenarioId(s.scenarioID)}
              >
                {
                  scenarioChildTypes.find(
                    x => x.listItemID == s.scenarioChildTypeID
                  )?.listItemName
                }
              </button>
            ))}
          </ButtonGroup>
        </>
      )}

      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}

      {!isLoading &&
        alternativeScores != undefined &&
        alternativeScores != null &&
        alternativeScores.length > 0 &&
        scenarioRatingScale != null &&
        scenarioRatingScale.length > 0 &&
        alternativeScores.map((a, idx) => {
          return (
            <Row key={idx}>
              {a != null && (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      backgroundColor: "lightyellow",
                      borderRadius: "10px",
                      padding: "15px",
                      marginLeft: "15%",
                      marginRight: "15%",
                    }}
                  >
                    <h5 style={{ color: "maroon" }}>{a.objMeasName}</h5>
                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        border: "1px solid gray",
                        padding: "15px",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>
                        <b>Mean: {Math.round(a.score * 100) / 100};</b>&nbsp;
                        <b>
                          Standard Deviation:{" "}
                          {Math.round(a.standDev * 100) / 100};
                        </b>
                        &nbsp;
                        <b>Group Rating: {a.verbalScore};</b>&nbsp;
                        <b>Score: {a.saaScore}</b>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  ></div>
                  {showGrids && (
                    <div style={{ marginTop: "20px" }}>
                      <AlternativeRatingTable
                        scenarioRatingScale={scenarioRatingScale}
                        userScores={a.userScores}
                        hasComments={true}
                        hasParticipants={currentUser.roleID > 5}
                        usersPhotos={usersPhotos}
                      />
                    </div>
                  )}
                  {showCharts && (
                    <div style={{ marginTop: "20px" }}>
                      <ReactApexChart
                        options={a.chartData.options}
                        series={a.chartData.series}
                        style={{ backgroundColor: "white" }}
                        type="bar"
                        height="250"
                      />
                    </div>
                  )}
                </div>
              )}
            </Row>
          )
        })}
    </React.Fragment>
  )
}

export default ScoringResultSection
