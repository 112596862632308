import CustomTable from "components/custom/customTable"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useFetcher from "hooks/useFetcher"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"

const TaskLogModal = ({ isOpen, toggleModal, scope, itemId, taskTypeId }) => {
  const currentUser = userService.getLoggedInUser()
  const [data, isLoading, loadData, setData] = useFetcher(() =>
    api.getTaskLog(scope, itemId, currentUser.userID, taskTypeId)
  )

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Task Log
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <CustomTable
              items={data}
              setItems={setData}
              hasPagination={false}
              columns={[
                {
                  title: "User",
                  field: "userName",
                  thStyle: { width: "20%" },
                  sort: true,
                },
                {
                  title: "Task",
                  field: "taskName",
                  thStyle: { width: "20%" },
                  sort: true,
                },
                {
                  title: "Date",
                  field: "logDT",
                  thStyle: { width: "20%" },
                  sort: true,
                  formatter: row => {
                    return !isNullOrEmpty(row.logDT)
                      ? moment(row.logDT).format("YYYY-MM-DD")
                      : ""
                  },
                },
                {
                  title: "Log Entry",
                  field: "logInfo",
                  thStyle: { width: "40%" },
                  sort: true,
                },
              ]}
            />
          )}
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default TaskLogModal
