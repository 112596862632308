import { mapObjPropsToLowercase } from "helpers/objPropsToLowercaseMapper"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ObjectiveRatingsGrid from "./ObjectiveRatingsGrid"
import { enrichWithPhotoBase64 } from "utils/avatar"

const PwcIndividual = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [objectiveDropdownSelection, setObjectiveDropdownSelection] =
    useState(null)
  let [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  let [objectives, setObjectives] = useState([])
  let [objectivesAndMeasures, setObjectivesAndMeasures] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [isCalculating, setIsCalculating] = useState(false)
  let [gridData, setGridData] = useState([])
  let [scenario, setScenario] = useState(null)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "pwcIndividual",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "pwcIndividual",
      })
    )
    loadData()
  }, [])

  useEffect(() => {
    if (objectiveDropdownSelection != null) {
      loadGrid()
    }
  }, [objectiveDropdownSelection])

  const loadData = async () => {
    try {
      setIsLoading(true)
      setIsCalculating(true)
      await api.calculateUserPercentages(scenarioId, currentUser.userID)
      let scen = await api.getScenario(scenarioId)
      setScenario(scen)
      let objs = await api.getObjectives(scenarioId)
      objs = objs.filter(x => x.objTypeID <= 0)
      setObjectives(objs)

      let objsAndMeasures = []
      objs.forEach(o => {
        objsAndMeasures.push(o)
        if (o.measures && o.measures.length > 0) {
          o.measures
            .filter(x => x.objTypeID <= 0)
            .forEach(m => {
              objsAndMeasures.push(m)
            })
        }
      })
      setObjectivesAndMeasures(objsAndMeasures)

      setObjectiveDropdownSelection({
        value: 0,
        text: scen.scenarioName,
      })

      let list = [
        {
          value: 0,
          text: scen.scenarioName,
        },
        {
          value: -99,
          text: scen.scenarioName + " with Measures",
        },
        ...objs
          .filter(x => x.measures && x.measures.length > 0)
          .map(x => {
            return {
              value: x.objectiveID,
              text: x.objectiveName,
            }
          }),
        {
          value: -1,
          text: "Inconsistency ratio",
        },
        {
          value: -2,
          text: "Variance",
        },
      ]
      if (objs.filter(x => x.measures && x.measures.length > 0).length == 0) {
        list.splice(1, 1)
      }
      setObjectivesDropdownList(list)
      setIsLoading(false)
      setIsCalculating(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadGrid = async () => {
    setIsLoading(true)
    let data = await api.getPwcResultsUsers(
      scenarioId,
      objectiveDropdownSelection.value
    )
    data = await enrichWithPhotoBase64(data, "userID")
    setGridData(mapObjPropsToLowercase(data))
    console.log(mapObjPropsToLowercase(data))
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          {objectiveDropdownSelection != null &&
            objectivesDropdownList.length > 0 && (
              <Row>
                <div style={{ width: "40%" }}>
                  <Label style={{ marginBottom: "0px" }}>View</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={objectiveDropdownSelection.value}
                    onChange={e => {
                      setObjectiveDropdownSelection(
                        objectivesDropdownList.find(
                          x => x.value == e.target.value
                        )
                      )
                    }}
                  >
                    {objectivesDropdownList.map((r, idx) => {
                      return (
                        <option key={idx} value={`${r.value}`}>
                          {r.text}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Row>
            )}
          <Row style={{ marginTop: "20px" }}>
            <Col>
              {isCalculating && (
                <h5 style={{ textAlign: "center" }}>
                  Recalculating each participants individual weighting factors
                </h5>
              )}
              {(isCalculating || isLoading) && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && scenario != null && gridData.length > 0 && (
                <ObjectiveRatingsGrid
                  data={gridData}
                  dropdownSelection={objectiveDropdownSelection}
                  objectives={objectives}
                  objectivesAndMeasures={objectivesAndMeasures}
                  scenario={scenario}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PwcIndividual
