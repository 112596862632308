import React, { useEffect, useRef, useState } from "react"
import { Tooltip } from "@material-ui/core"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BarChartModal from "./BarChartModal"
import anychart from "anychart"
import RadarChartModal from "./RadarChartModal"
import AltH2hChart from "./AltH2hChartModal"
import { swalWithConfirmButton } from "components/custom/swal"
import AltScatterChartModal from "./AltScatterChartModal"
import { changeNavbarParams, loadPageItem } from "store/actions"
import BubbleChart from "../SetBubbleChart/BubbleChart"
import { ObjFinAlert } from "services/scenario.service"
import { ReactTabulator } from "react-tabulator"
import { mapAllPropsToLowercase } from "helpers/objPropsToLowercaseMapper"
import {
  getSelectHtmlFilterValues,
  getSelectedVisibleRows,
} from "utils/tabulatorFilter"
import useModal from "hooks/useModalHook"
import ConfigModal from "pages/Projects/Projects/ConfigModal"
import { createRoot } from "react-dom/client"
import ProjectsContext from "pages/Projects/Projects/Context/ProjectsContext"
import FinancialChartModal from "./FinancialChartModal"
import { customFormatter } from "utils/tabulator"

const Charts = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  var tabulatorRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  const [selectedObj, setSelectedObj] = useState(0)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [showChartsDropdown, setShowChartsDropdown] = useState(false)
  const [gridData, setGridData] = useState([])
  const [altIdsList, setAltIdsList] = useState([])
  const [hasSecondaryScores, setHasSecondaryScores] = useState(true)

  const [isBarChartModalVisible, setIsBarChartModalVisible] = useState(false)
  const toggleBarChartModal = () =>
    setIsBarChartModalVisible(!isBarChartModalVisible)
  const [isRadarChartModalVisible, setIsRadarChartModalVisible] =
    useState(false)
  const toggleRadarChartModal = () =>
    setIsRadarChartModalVisible(!isRadarChartModalVisible)
  const [isAltH2hChartModalVisible, setIsAltH2hChartModalVisible] =
    useState(false)
  const toggleAltH2hChartModal = () =>
    setIsAltH2hChartModalVisible(!isAltH2hChartModalVisible)
  const [isScatterChartModalVisible, setIsScatterChartModalVisible] =
    useState(false)
  const toggleScatterChartModal = () =>
    setIsScatterChartModalVisible(!isScatterChartModalVisible)
  const [isBubbleChartModalVisible, setIsBubbleChartModalVisible] =
    useState(false)
  const toggleBubbleChartModal = () =>
    setIsBubbleChartModalVisible(!isBubbleChartModalVisible)
  const { show: showFinancialChartModal, toggle: toggleFinancialChartModal } =
    useModal()

  const [sortProp, setSortProp] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  const [showFilters, setShowFilters] = useState(true)

  const [decisionFilter, setDecisionFilter] = useState("")
  const [excludedFilter, setExcludedFilter] = useState("")

  const [configItems, setConfigItems] = useState([])
  const [columns, setColumns] = useState([])

  const { show: showConfigModal, toggle: toggleConfigModal } = useModal()

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "AnalysisChart",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "AnalysisChart",
      })
    )
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    loadData()
  }, [optObjFin])

  useEffect(() => {
    if (configItems.length > 0 && gridData.length > 0) {
      setAltIdsList([])
      loadColumns()
    }
  }, [configItems, gridData])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let cfg = await api.getConfigItems(
        currentUser.userID,
        scenarioId,
        "Charts"
      )
      setConfigItems(cfg)
      let scen = await api.getScenario(scenarioId)
      let objs = await api.getObjectives(scen.scenarioID)
      setObjectivesDropdownList([
        {
          value: 0,
          text: scen.scenarioName,
        },
        ...objs
          .filter(x => x.measures && x.measures.length > 0)
          .map(x => {
            return {
              value: x.objectiveID,
              text: x.objectiveName,
            }
          }),
      ])
      await loadGrid(selectedObj)
      setIsLoading(false)
    } catch (er) {
      console.log(er)
      setIsLoading(false)
    }
  }

  const loadGrid = async objId => {
    let { obj, fin } = optObjFin
    let data = await api.getAlternativesForCharts(
      scenarioId,
      objId,
      obj == null ? 0 : obj.objModelID,
      fin == null ? 0 : fin.finModelID
    )
    let hasSs = false
    for (var i = 0; i < data.length; i++) {
      if (data[i].secondaryScoreNumber > 0) {
        hasSs = true
        break
      }
    }

    setHasSecondaryScores(hasSs)
    setGridData(
      sortAsc(
        mapAllPropsToLowercase(enrichWithRunningCostAndBenefitFields(data)),
        sortProp
      )
    )
  }

  const enrichWithRunningCostAndBenefitFields = alts => {
    let copy = [...alts]
    for (let i = 0; i < copy.length; i++) {
      if (i == 0) {
        copy[i].runningCost = copy[i].cost
        copy[i].runningBenefit = copy[i].benefit
      } else {
        let prev = copy[i - 1]
        copy[i].runningCost = prev.runningCost + copy[i].cost
        copy[i].runningBenefit = prev.runningBenefit + copy[i].benefit
      }
    }
    return copy
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  const showAltNotSelectedAlert = () => {
    swalWithConfirmButton.fire({
      title: `To run the chart, you must select at least one project.`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })
  }

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const loadColumns = () => {
    let cols = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        cellClick: function (e, cell) {
          cell.getRow().toggleSelect()
        },
        width: 70,
      },
      {
        title: "ID",
        field: "alternativenum",
        width: 80,
        headerFilter: "input",
      },
      {
        title: "Project",
        field: "alternative",
        minWidth: 400,
        headerFilter: "input",
      },
      {
        title: "Version",
        field: "version",
        headerFilter: "select",
        headerFilterFunc: "in",
        headerFilterParams: {
          values: getSelectHtmlFilterValues(
            new Set(
              gridData
                .map(u => u["version"])
                .filter(x => x !== undefined && x !== null && x !== "")
            )
          ),
          multiselect: true,
        },
        width: 100,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
        hozAlign: "center",
      },
      {
        title: "Excluded",
        field: "excludestring",
        minWidth: 100,
        formatter: (cell, formatterParams, onRendered) => {
          let val = cell.getValue()
          if (val == undefined) {
            return ""
          }
          return `<span style="color:red">${val}</span>`
        },
      },
      {
        title: "Benefit Score",
        field: "saascore",
        minWidth: 200,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
      },
    ]

    if (hasSecondaryScores) {
      cols.push({
        title: "Risk Score",
        field: "secondaryscore",
        minWidth: 200,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
      })
    }

    /*     cols.push({
      title: "Decision",
      field: "finaldecision",
      minWidth: 100,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    }) */

    cols.push({
      title: "Decision",
      field: "finaldecision",
      minWidth: 100,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
      headerFilter: "select",
      headerFilterFunc: "in",
      headerFilterParams: {
        values: getSelectHtmlFilterValues(
          new Set(
            gridData
              .map(u => u["finaldecision"])
              .filter(x => x !== undefined && x !== null && x !== "")
          )
        ),
        sortValuesList: "asc",
        multiselect: true,
      },
    })

    configItems
      .filter(x => x.configType == "CB")
      .map(x => {
        let colParam = x.colParam.toLowerCase()
        if (colParam == "cost") {
          x.orderid = 1
        } else if (colParam == "benefit") {
          x.orderid = 2
        } else if (colParam == "return") {
          x.orderid = 3
        } else if (colParam == "roi") {
          x.orderid = 5
        } else if (colParam == "irr") {
          x.orderid = 4
        } else if (colParam == "pbp") {
          x.orderid = 6
        } else if (colParam == "projectmanager") {
          x.orderid = 7
        } else if (colParam == "sponsor") {
          x.orderid = 8
        } else {
          x.orderid = 100
        }

        return x
      })
      .sort(function (a, b) {
        if (a.orderid < b.orderid) {
          return -1
        }
        if (a.orderid > b.orderid) {
          return 1
        }
        return 0
      })
      .forEach(ci => {
        if (ci.configItemValue == "1") {
          const colParam = ci.colParam.toLowerCase()
          let col = {
            title: ci.displayName,
            field: colParam == "return" ? "returnamount" : colParam,
            maxWidth: 300,
          }

          if (
            colParam == "return" ||
            colParam == "cost" ||
            colParam == "costreserve" ||
            colParam == "benefit" ||
            colParam == "roi" ||
            colParam == "irr" ||
            colParam == "pbp" ||
            colParam == "paybackyears"
          ) {
            col.hozAlign = "end"
          }

          if (
            colParam == "return" ||
            colParam == "cost" ||
            colParam == "benefit"
          ) {
            col.hozAlign = "end"
            col.formatter = (cell, formatterParams, onRendered) => {
              return currencyFormatter.format(cell.getValue())
            }
          }
          col = addFilter(col, gridData)
          cols.push(col)

          if (col.field == "cost") {
            let runningCostCol = {
              title: "Running Cost",
              field: "runningcost",
              maxWidth: 300,
              hozAlign: "end",
              formatter: function (cell, formatterParams, onRendered) {
                return currencyFormatter.format(cell.getValue())
              },
            }
            cols.push(runningCostCol)
          }
          if (col.field == "benefit") {
            let runningBenefitCol = {
              title: "Running Benefit",
              field: "runningbenefit",
              maxWidth: 300,
              hozAlign: "end",
              formatter: function (cell, formatterParams, onRendered) {
                return currencyFormatter.format(cell.getValue())
              },
            }
            cols.push(runningBenefitCol)
          }
        }
      })

    cols.push({
      title: "Decision Notes",
      field: "decisionnotes",
      minWidth: 250,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <Tooltip title={<h6>{cellValue}</h6>}>
          <div className="one-line-elipsis">{cellValue}</div>
        </Tooltip>
      )),
    })

    if (scenarioId < 0) {
      cols.splice(2, 0, {
        title: "Model",
        field: "scenarioname",
        minWidth: 250,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
        minWidth: 100,
        // frozen: true,
      })
    }

    setColumns(cols)
  }

  const addFilter = (col, data) => {
    if (
      col.field == "bcstatepill" ||
      col.field == "investmentcategory" ||
      col.field == "brmuser" ||
      col.field == "pmuser" ||
      col.field == "decision" ||
      col.field == "submitter" ||
      col.field == "workflowstatus" ||
      col.field == "lead" ||
      col.field == "leaddeliveryorganization" ||
      col.field == "program" ||
      col.field == "projectcategory" ||
      col.field == "sponsoringunit" ||
      col.field == "deliverypocuser"
    ) {
      let prop = col.field
      col.headerFilter = "select"
      col.headerFilterFunc = "in"
      col.headerFilterParams = {
        values: getSelectHtmlFilterValues(
          new Set(
            data
              .map(u => u[prop])
              .filter(x => x !== undefined && x !== null && x !== "")
          )
        ),
        sortValuesList: "asc",
        multiselect: true,
      }
    } else if (
      col.field == "usediscount" ||
      col.field == "limitaccess" ||
      col.field == "multiyear" ||
      col.field == "inactive" ||
      col.field == "exclude" ||
      col.field == "fyplanned" ||
      col.field == "fysubmitted"
    ) {
      col.headerFilter = "select"
      col.headerFilterParams = {
        values: true,
        sortValuesList: "asc",
      }
    }

    return col
  }

  return (
    <React.Fragment>
      <ProjectsContext.Provider
        value={{
          bcStatusOptions: [],
          completeOptions: [],
          brmOptions: [],
          sponsorOptions: [],
          ldoOptions: [],
          ldoListOptions: [],
          pmOptions: [],
          deliveryOptions: [],
          submitterOptions: [],
          workflowOptions: [],
          planningOptions: [],
          investmentOptions: [],
          decisionOptions: [],
          programs: [],
        }}
      >
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
            />
            <Card>
              <CardBody>
                {isLoading && (
                  <Loader
                    type="line-scale-pulse-out"
                    color={colors.primary}
                    style={{ textAlign: "center" }}
                  />
                )}
                {!isLoading && (
                  <>
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="mb-3">
                          {objectivesDropdownList.length > 1 && scenarioId > 0 && (
                            <>
                              <Label className="form-label">View</Label>
                              <select
                                className="form-control form-select select2 mb-xxl-0"
                                value={selectedObj || 0}
                                style={{ width: "400px" }}
                                onChange={e => {
                                  setSelectedObj(e.target.value)
                                  loadGrid(e.target.value)
                                }}
                              >
                                {objectivesDropdownList.map((a, idx) => {
                                  return (
                                    <option key={idx} value={`${a.value}`}>
                                      {a.text}
                                    </option>
                                  )
                                })}
                              </select>
                            </>
                          )}
                        </div>
                        <div
                          className="mb-3"
                          style={{ alignSelf: "end", display: "flex" }}
                        >
                          <button
                            onClick={toggleFilters}
                            className="btn btn-outline-primary"
                            style={{ float: "right" }}
                          >
                            {showFilters ? "Hide" : "Show"}{" "}
                            <i className="fas fa-search"></i>{" "}
                            <i className="fas fa-filter"></i>
                          </button>
                          <Dropdown
                            // style={{ display: "inline-block" }}
                            isOpen={showActionsDropdown}
                            toggle={() =>
                              setShowActionsDropdown(!showActionsDropdown)
                            }
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-outline-primary save-user"
                            >
                              Actions <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  toggleConfigModal()
                                }}
                              >
                                <i className="fas fa-cog"></i> Configure View
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          <Dropdown
                            // style={{ display: "inline-block" }}
                            isOpen={showChartsDropdown}
                            toggle={() =>
                              setShowChartsDropdown(!showChartsDropdown)
                            }
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-outline-primary save-user"
                            >
                              Select Chart{" "}
                              <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {scenarioId > 0 && (
                                <DropdownItem
                                  onClick={() => {
                                    if (altIdsList.length > 0) {
                                      toggleBarChartModal()
                                    } else {
                                      showAltNotSelectedAlert()
                                    }
                                  }}
                                >
                                  <i className="mdi mdi-chart-timeline"></i> Bar
                                  Chart
                                </DropdownItem>
                              )}

                              {hasSecondaryScores && (
                                <DropdownItem
                                  onClick={() => {
                                    if (altIdsList.length > 0) {
                                      toggleBubbleChartModal()
                                    } else {
                                      showAltNotSelectedAlert()
                                    }
                                  }}
                                >
                                  <i className="mdi mdi-chart-scatter-plot-hexbin"></i>{" "}
                                  Bubble Chart
                                </DropdownItem>
                              )}

                              <DropdownItem
                                onClick={() => {
                                  if (altIdsList.length > 0) {
                                    toggleFinancialChartModal()
                                  } else {
                                    showAltNotSelectedAlert()
                                  }
                                }}
                              >
                                <i className="mdi mdi-chart-line"></i> Financial
                                Chart
                              </DropdownItem>

                              {scenarioId > 0 && (
                                <DropdownItem
                                  onClick={() => {
                                    if (altIdsList.length == 2) {
                                      toggleAltH2hChartModal()
                                    } else {
                                      swalWithConfirmButton.fire({
                                        title: `For Head to Head chart you must select exactly 2 projects`,
                                        icon: "warning",
                                        showCancelButton: false,
                                        confirmButtonText: "Ok",
                                      })
                                    }
                                  }}
                                >
                                  <i className="mdi mdi-chart-gantt"></i> H2H
                                  Comparison
                                </DropdownItem>
                              )}

                              {scenarioId > 0 && (
                                <DropdownItem
                                  onClick={() => {
                                    if (altIdsList.length > 0) {
                                      toggleRadarChartModal()
                                    } else {
                                      showAltNotSelectedAlert()
                                    }
                                  }}
                                >
                                  <i className="mdi mdi-radar"></i> Radar Plot
                                </DropdownItem>
                              )}

                              <DropdownItem
                                onClick={() => {
                                  if (altIdsList.length > 0) {
                                    toggleScatterChartModal()
                                  } else {
                                    showAltNotSelectedAlert()
                                  }
                                }}
                              >
                                <i className="mdi mdi-chart-scatter-plot"></i>{" "}
                                Scatter Chart
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Col>
                    </Row>
                    <ObjFinAlert optObjFin={optObjFin} />
                    <Row>
                      <Col>
                        {columns.length > 0 && (
                          <ReactTabulator
                            onRef={r => (tabulatorRef.current = r.current)}
                            columns={columns}
                            data={gridData}
                            options={{
                              pagination: "local",
                              paginationSize:
                                configItems.find(
                                  x => x.configType == "Pager"
                                ) !== undefined
                                  ? configItems.find(
                                      x => x.configType == "Pager"
                                    ).configItemValue ?? 30
                                  : 30,
                              paginationSizeSelector: [
                                5, 10, 20, 30, 50, 100, 200, 300,
                              ],
                              layout:
                                columns.length > 6
                                  ? "fitDataStretch"
                                  : "fitColumns",
                              cssClass: showFilters
                                ? "show-filters"
                                : "hide-filters",
                              maxHeight: "100%",
                            }}
                            events={{
                              rowSelectionChanged: function (data, rows) {
                                let selectedVisibleData =
                                  getSelectedVisibleRows(tabulatorRef)

                                setAltIdsList(
                                  selectedVisibleData.map(x => x.alternativeid)
                                )
                              },
                              dataSorted: function (data, rows) {},
                              dataFiltered: function (filters, rows) {
                                rows.forEach((row, idx) => {
                                  const data = row.getData()
                                  if (idx == 0) {
                                    data.runningcost = data.cost
                                    data.runningbenefit = data.benefit
                                  } else {
                                    let prevData = rows[idx - 1].getData()
                                    data.runningcost =
                                      prevData.runningcost + data.cost
                                    data.runningbenefit =
                                      prevData.runningbenefit + data.benefit
                                  }

                                  row.update({
                                    runningcost: data.runningcost,
                                    runningbenefit: data.runningbenefit,
                                  })
                                })
                              },
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
        <BarChartModal
          scenarioId={scenarioId}
          alternativeList={altIdsList}
          objectiveId={selectedObj}
          isOpen={isBarChartModalVisible}
          toggleModal={toggleBarChartModal}
          optObjFin={optObjFin}
        />
        <RadarChartModal
          scenarioId={scenarioId}
          alternativeList={altIdsList}
          isOpen={isRadarChartModalVisible}
          toggleModal={toggleRadarChartModal}
          optObjFin={optObjFin}
        />
        <AltH2hChart
          scenarioId={scenarioId}
          alternativeList={altIdsList}
          isOpen={isAltH2hChartModalVisible}
          toggleModal={toggleAltH2hChartModal}
          optObjFin={optObjFin}
        />
        <AltScatterChartModal
          scenarioId={scenarioId}
          alternativeList={altIdsList}
          isOpen={isScatterChartModalVisible}
          toggleModal={toggleScatterChartModal}
          optObjFin={optObjFin}
        />
        <BubbleChart
          scenarioId={scenarioId}
          alternativeList={altIdsList}
          isOpen={isBubbleChartModalVisible}
          toggleModal={toggleBubbleChartModal}
          optObjFin={optObjFin}
        />

        <ConfigModal
          isOpen={showConfigModal}
          toggleModal={toggleConfigModal}
          reloadData={loadData}
          scenarioId={scenarioId}
          configType="Charts"
        />

        <FinancialChartModal
          isOpen={showFinancialChartModal}
          toggleModal={toggleFinancialChartModal}
          scenarioId={scenarioId}
          alternativeList={altIdsList}
        />
      </ProjectsContext.Provider>
    </React.Fragment>
  )
}

export default Charts
