import useFetcher from "hooks/useFetcher"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditCostPoolModal = ({
  isOpen,
  toggleModal,
  costPoolToEdit,
  setCostPoolToEdit,
  inflationRates,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [selectedCostPoolTypeIds, setSelectedCostPoolTypeIds] = useState([])

  const [costPoolTypes] = useFetcher(
    () => api.getList(currentUser.userID, "CostPoolTypes"),
    []
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedCostPoolTypeIds([])
    }
  }, [isOpen])

  const save = async () => {
    if (costPoolToEdit.costPoolID == -1) {
      let tasks = selectedCostPoolTypeIds.map(x => async () => {
        return await api.createCostPool(currentUser.userID, {
          ...costPoolToEdit,
          costPoolTypeID: x,
        })
      })
      await Promise.all(tasks.map(t => t()))

      //   await api.createCostPool(currentUser.userID, costPoolToEdit)

      toast.success("Cost Pools Saved")
      toggleModal()
      reload && reload()
    }
  }

  if (costPoolToEdit == null) return
  else
    return (
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Cost Pool
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* add costpooltemplate */}
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              type="text"
              onChange={e =>
                setCostPoolToEdit({
                  ...costPoolToEdit,
                  costPoolName: e.target.value,
                })
              }
              value={costPoolToEdit.costPoolName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Segments:</Label>
            {costPoolTypes.map((t, idx) => (
              <div key={idx} className="form-check">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id={`ff-cpt-${idx}`}
                  checked={
                    selectedCostPoolTypeIds.find(x => x == t.listItemID) > -1
                  }
                  onClick={e => {
                    if (
                      selectedCostPoolTypeIds.find(x => x == t.listItemID) > -1
                    ) {
                      setSelectedCostPoolTypeIds(
                        selectedCostPoolTypeIds.filter(x => x != t.listItemID)
                      )
                    } else {
                      setSelectedCostPoolTypeIds([
                        ...selectedCostPoolTypeIds,
                        t.listItemID,
                      ])
                    }
                  }}
                />
                <Label className="form-check-label" for={`ff-cpt-${idx}`}>
                  {t.listItemName}
                </Label>
              </div>
            ))}
          </div>
          <div className="mb-3">
            <Label className="form-label">Inflation Rate</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={costPoolToEdit.inflationRateID}
              onChange={e => {
                setCostPoolToEdit({
                  ...costPoolToEdit,
                  inflationRateID: e.target.value,
                })
              }}
            >
              <option value={0}>None</option>
              {inflationRates.map((r, idx) => (
                <option key={idx} value={r.bcRateID}>
                  {r.bcRateName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-check mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="ff-Through"
              checked={costPoolToEdit.flowThrough}
              onClick={e => {
                setCostPoolToEdit({
                  ...costPoolToEdit,
                  flowThrough: !costPoolToEdit.flowThrough,
                })
              }}
            />
            <Label className="form-check-label" for="ff-Through">
              Resource Flow Through
            </Label>
          </div>
          {/* <div className="form-check mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="ff-usecp"
              checked={formFieldToEdit.alwaysReadOnly}
              onClick={e => {
                setCostPoolToEdit({
                    ...costPoolToEdit,
                    
                  })
              }}
            />
            <Label className="form-check-label" for="ff-usecp">
              Use Cost Pool
            </Label>
          </div> */}
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {/* {rateToEdit.bcRateID != -1 && (
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginRight: "auto" }}
              onClick={deleteRate}
            >
              Delete
            </button>
          )} */}
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
            disabled={
              costPoolToEdit == null ||
              costPoolToEdit.costPoolName == "" ||
              selectedCostPoolTypeIds.length == 0
            }
          >
            Save
          </button>
        </div>
      </Modal>
    )
}

export default EditCostPoolModal
